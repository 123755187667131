import { FORMS } from "../../../redux/slices/forms";
import { MISSING_AIS_MAX_HOURS } from "../../const";
import { convertSecondsToHours, validPositiveNumber } from "../../measurement";
import { Form } from "./Form";

export default class VesselMissingAisForm extends Form {
    /**
     * Create a new instance of a VesselMissingAisForm based on data from an alert rule
     * @param {AlertRule} alertRule
     * @param {function(VesselMissingAisForm)} updateExternalState
     * @returns {VesselMissingAisForm}
     */
    static fromAlertRule(alertRule, updateExternalState) {
        return VesselMissingAisForm.deserialize({
            hours: convertSecondsToHours(alertRule.getMissingAisSeconds()) || '',
            key: FORMS.ALERT_RULE_VESSEL_MISSING_AIS,
        }, updateExternalState);
    }

    /**
     * @returns {string|number}
     */
    getHoursValue() {
        return this._data.hours;
    }

    /**
     * @param {string|number} hours
     */
    setHoursValue(hours) {
        this._data.hours = hours;
        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * Validates the form and sets any errors.
     * @param {{ allowEmpty: boolean }} options
     * @returns {boolean} true if valid, otherwise false
     */
    validate({ allowEmpty = false } = {}) {
        const newErrors = {};
        newErrors.hours = !validPositiveNumber(this.getHoursValue(), { allowEmpty })
            || Number(this.getHoursValue()) > MISSING_AIS_MAX_HOURS;
        this.setErrors(newErrors);
        return !this.hasErrors();
    }
}
