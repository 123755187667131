/* eslint-disable no-empty-function */

import { captureRollbarEvent } from "./rollbar";

/**
 * A class to essentially disable caching, doesn't write or read anything from a cache.
 * Used when CacheIdb isn't available.
 */
export default class CacheDevNull {

    /**
     * @param name
     * @param key
     */
    constructor(name, key) {
        this.name = name;
        this.key = key;
        this.db = null;
    }

    /**
     * @returns {Promise<null>}
     */
    async getDb() {
        return null;
    }

    /**
     * @returns {Promise<undefined>}
     */
    async get(_, log = false) {
        if (log) {
            captureRollbarEvent({ message: `CacheDevNull.get called` });
        }
        return undefined;
    }

    /**
     * @return {Promise<[]>}
     */
    async getMultiple() {
        return [];
    }

    /**
     * @returns {Promise<null>}
     */
    async set() {
        return null;
    }

    /**
     * @returns {Promise<null>}
     */
    async setMultiple() {
        return null;
    }

    /**
     *
     * @returns {Promise<[]>}
     */
    async allKeys() {
        return [];
    }

    /**
     * @return {Promise<[]>}
     */
    async checkMultipleExist() {
        return [];
    }

    /**
     *
     * @returns {Promise<number>}
     */
    async deleteMultiple() {
        return 0;
    }

    /**
     * @return {Promise<number>}
     */
    async deleteBeforeTs() {
        return 0;
    }

    /**
     * @return {Promise<number>}
     */
    async deleteByTtl() {
        return 0;
    }

    /**
     * @returns {Promise<void>}
     */
    async clear() {}
}
