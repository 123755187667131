// Disable, functions are in line with Leaflet functions
/* eslint-disable func-names */
import * as L from "leaflet";
import "./Segment";

const PumpkinLine = L.Segment.extend({

    initialize: function (latLngs, midLatLng, options) {
        this.midLatLng = midLatLng;
        L.Segment.prototype.initialize.call(this, latLngs, options);
    },

    _updatePath: function () {
        this._renderer.updatePumpkinLine(this);
    },
});

// Add a shortcut to the global Leaflet object
L.pumpkinLine = function shortcut(start, end, map, options) {
    return new PumpkinLine(start, end, map, options);
};
L.PumpkinLine = PumpkinLine;
