// Disable, functions are in line with Leaflet functions
/* eslint-disable func-names */
import * as L from 'leaflet';
import { BLUR_OPACITY } from '../const';
import { AWOL_RADIUS, LAYER_TYPE_TRACK, lineColor, lineWeight, OPACITY_FULL, OPACITY_HIDE } from "../map";

const PumpkinCircle = L.CircleMarker.extend({

    layerType: LAYER_TYPE_TRACK,

    options: {
        // Leaflet options
        interactive: true,
        bubblingMouseEvents: false,

        opacity: OPACITY_FULL,
        dashArray: '1, 5',
        dashOffset: '0',
        radius: AWOL_RADIUS,
        fillOpacity: OPACITY_HIDE,
        transshipment: false,
        speedColor: null,

        selected: false,
    },

    initialize: function (latlng, options) {
        L.CircleMarker.prototype.initialize.call(this, latlng, options);
        this._calcStyles();
    },

    _updatePath: function () {
        this._renderer.updatePumpkinCircle(this);
    },

    /**
     * Update style properties that can change interactively (e.g. when a segment is selected)
     * @private
     */
    _calcStyles: function () {
        this.options.color = lineColor({
            selected: this.options.selected,
            transshipment: this.options.transshipment,
            speedColor: this.options.speedColor,
        });
        this.options.weight = lineWeight({ selected: this.options.selected });

        if (this.options.highlighted) {
            this.options.opacity = BLUR_OPACITY.HIGHLIGHTED_TRACK;
        } else {
            this.options.opacity = this.options.blurred ? BLUR_OPACITY.TRACK : 1;
        }
    },

    /**
     * Update the layer styles
     * @private
     */
    _refreshStyle: function () {
        if (this._renderer) {
            this._renderer._updateStyle(this);
        }
    },

    select: function () {
        this.options.selected = true;
        this._calcStyles();
        this._refreshStyle();
    },

    deSelect: function () {
        this.options.selected = false;
        this._calcStyles();
        this._refreshStyle();
    },

    // Mouseover callback - adds a highlight to a selected but blurred track
    mouseOver: function () {
        if (this.options.blurred) {
            this.options.highlighted = true;
            this._calcStyles();
            this._refreshStyle();
        }
    },

    // Removes highlight
    mouseOut: function () {
        this.options.highlighted = false;
        this._calcStyles();
        this._refreshStyle();
    },
});

// Allow this class to be referenced, e.g. blah instanceof L.PumpkinCircle
L.PumpkinCircle = PumpkinCircle;

// Add a shortcut to the global Leaflet object
L.pumpkinCircle = function shortcut(latlng, options) {
    return new PumpkinCircle(latlng, options);
};
