import { connect } from "react-redux";
import { clearForm } from "../../redux/slices/forms";
import VesselSpeedFormUi from "./VesselSpeedFormUi";
import { getUserPrefsDistanceUnit } from '../../redux/derived-data';

const mapStateToProps = (state) => {
    return {
        distanceUnit: getUserPrefsDistanceUnit(state),
    };
};

export default connect(
    mapStateToProps,
    {
        clearForm,
    },
)(VesselSpeedFormUi);
