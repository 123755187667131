import AlertRule from "../AlertRule";
import ActivityBase from "./ActivityBase";

export default class ActivitySingleAlert extends ActivityBase {
    static name = "ActivitySingleAlert";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.alerts.singleResult;
    }

    /**
     * @returns {Object[]}
     */
    get alerts() {
        const alerts = this.data.alerts.map((alert) => {
            return {
                ...alert,
                alertTriggeredMetaData: JSON.parse(alert.alertTriggeredMetaData),
            };
        });
        return alerts;
    }

    /**
     * @returns {Object}
     */
    get alertRule() {
        return AlertRule.deserialize(this.data.alertRule);
    }

    /**
     * @returns {Object}
     */
    get vesselProfile() {
        return this.data.vesselProfile;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivitySingleAlert-${this.ts}-${this.vesselProfile.vesselId}-${this.alertRule.getId()}`;
    }
}
