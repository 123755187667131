import intersection from "lodash.intersection";
import { TAGS_MAX_LENGTH } from "../config";
import { OPERATORS, TAG_PERMISSIONS } from "./const";
import { trimAndLowerCase } from "./grammar";
import { sendToRollbar } from "./rollbar";
import { arrayIsSuperset, arraysIntersect, getNumericKeys } from "./utils";
import { textColorFromBackground } from "./colorUtils";

/**
 * Tags comparator, sort by tag name ascending
 * @param {Object} t1
 * @param {Object} t2
 * @returns {Number}
 */
export function sortByTagName(t1, t2) {
    return t1.tagName.localeCompare(t2.tagName, 'en', { sensitivity: 'base' });
}

/**
 * The preferred order for tags is by type of permission first, then alphabetical (within the same permission group)
 * @param {Object} t1
 * @param {Object} t2
 * @param {Array} permissionsOrder
 * @returns {Number}
 */
export function sortByPermissionsTagName(t1, t2, permissionsOrder = [TAG_PERMISSIONS.OWNER, TAG_PERMISSIONS.EDIT, TAG_PERMISSIONS.VIEW]) {
    // t1 and t2 can either be tag objects OR other objects that have a `tag` property
    t1 = t1.tag ?? t1;
    t2 = t2.tag ?? t2;

    const permissionOrder1 = permissionsOrder.indexOf(t1.permissions || TAG_PERMISSIONS.OWNER);
    const permissionOrder2 = permissionsOrder.indexOf(t2.permissions || TAG_PERMISSIONS.OWNER);

    const sameGroup = permissionOrder1 === permissionOrder2;
    if (!sameGroup) {
        return permissionOrder1 - permissionOrder2;
    }

    return t1.tagName.localeCompare(t2.tagName, 'en', { sensitivity: 'base' });
}

/**
 * Orders tags by permission and then by count (within the same permission group), then alphabetically (within the same count)
 * @param {Object} t1
 * @param {Object} t2
 * @param {Array} permissionsOrder
 * @returns {Number}
 */
export function sortByPermissionsTagCount(t1, t2, permissionsOrder = [TAG_PERMISSIONS.OWNER, TAG_PERMISSIONS.EDIT, TAG_PERMISSIONS.VIEW]) {
    const permissionOrder1 = permissionsOrder.indexOf(t1.tag.permissions || TAG_PERMISSIONS.OWNER);
    const permissionOrder2 = permissionsOrder.indexOf(t2.tag.permissions || TAG_PERMISSIONS.OWNER);

    const sameGroup = permissionOrder1 === permissionOrder2;
    if (!sameGroup) {
        return permissionOrder1 - permissionOrder2;
    }

    const countsDifference = t2.count - t1.count;
    if (countsDifference === 0) {
        return sortByTagName(t1.tag, t2.tag);
    }

    return countsDifference;
}

/**
 * Validate a tag name. Tag can't be blank. Max 50 chars. Can't be a duplicate.
 * @param {String} tagName
 * @param {Object[]} allTags
 * @returns {Boolean} True if valid
 */
export function validTagName(tagName, allTags) {
    tagName = trimAndLowerCase(tagName);
    return tagName !== '' &&
        tagName.length <= TAGS_MAX_LENGTH &&
        !allTags.find((tag) => trimAndLowerCase(tag.tagName) === tagName);
}

/**
 * Get a list of vessel ids that match tag filter criteria
 * @param {Object} tagIdsByVesselId
 * @param {Number[]} tagIds
 * @param {String} op
 * @returns {Number[]} vessel ids
 */
export function getVesselIdsFromTagsFilter(tagIdsByVesselId, tagIds, op) {
    const vesselIds = getNumericKeys(tagIdsByVesselId);

    // If no tags then just return all tagged vessels
    if (tagIds.length === 0) {
        return vesselIds;
    }

    if (op === OPERATORS.OR) {
        return vesselIds.filter((vesselId) => arraysIntersect(tagIds, tagIdsByVesselId[vesselId]));
    }

    // If we've got to here it must be OPERATORS.AND
    return vesselIds.filter((vesselId) => arrayIsSuperset(tagIdsByVesselId[vesselId], tagIds));
}

/**
 * Return true if all given vessels are tagged with tag id
 * @param {Number} tagId
 * @param {Number[]} vesselIds
 * @param {Object} vesselIdsByTagId
 * @returns {Boolean}
 */
export function allVesselsTagged(tagId, vesselIds, vesselIdsByTagId) {
    return vesselIds.every((vesselId) => vesselIdsByTagId[tagId].includes(vesselId));
}

/**
 * Return true if some given vessels are tagged with tag id
 * @param {Number} tagId
 * @param {Number[]} vesselIds
 * @param {Object} vesselIdsByTagId
 * @returns {Boolean}
 */
export function someVesselsTagged(tagId, vesselIds, vesselIdsByTagId) {
    return vesselIds.some((vesselId) => vesselIdsByTagId[tagId].includes(vesselId));
}

/**
 * Gets the labels for the tag groups with the owner permission being the current org name
 * @param {Object} orgName
 * @returns {Object}
 */
export function getTagPermissionLabels(orgName) {
    return {
        [TAG_PERMISSIONS.OWNER]: `${orgName}`,
        [TAG_PERMISSIONS.EDIT]: 'Shared',
        [TAG_PERMISSIONS.VIEW]: 'Global',
    };
}

/**
 * Groups tags by permission (owner, edit, view)
 * @param {object} tags
 * @returns {object}
 */
export function groupTagsByPermissions(tags) {
    const tagGroups = {};
    const allowedGroups = Object.values(TAG_PERMISSIONS);

    Object.values(tags).forEach((tag) => {
        // TODO: until the backend is integrated, there is a slight chance that tags won't have permissions defined or
        // the permissions it has are not ones we accounted for. If that happens remove them from grouping and send the
        // error to rollbar.
        // TODO: consider removing this when backend has permissions integrated.
        if (!allowedGroups.includes(tag.permissions)) {
            sendToRollbar("Error occurred while filtering tags, group key not found", tag.permissions);
            return;
        }

        if (!tagGroups[tag.permissions]) {
            tagGroups[tag.permissions] = [];
        }
        tagGroups[tag.permissions].push(tag);
    });

    return tagGroups;
}

/**
 * If permissions are view-only, user can't add or remove vessels to tag.
 *
 * TODO: this is user and createdBy dependant but that part of the functionality hasn't been worked on yet
 *
 * @param {object} tag
 * @returns {boolean}
 */
export function canEditTaggings(tag) {
    return tag?.permissions === TAG_PERMISSIONS.EDIT || tag?.permissions === TAG_PERMISSIONS.OWNER;
}

/**
 * If permissions are 'owner', only the user that owns the tag can edit it.
 *
 * TODO: this is user and createdBy dependant but that part of the functionality hasn't been worked on yet
 *
 * @param {object} tag
 * @returns {boolean}
 */
// eslint-disable-next-line import/no-unused-modules
export function canEditTag(tag) {
    return tag?.permissions === TAG_PERMISSIONS.OWNER;
}

/**
 * Checks that a list of tag ids is available to organisation and
 * removes any tags that org does not have access to
 * @param {Object} orgTags - getTags(state)
 * @param {Array} tagIdsToCheck
 * @returns {*}
 */
export function removeTagsUnavailableToOrg(orgTags, tagIdsToCheck) {
    return intersection(tagIdsToCheck, Object.values(orgTags).map((tag) => tag.tagId));
}

/**
 * Removes any global tags from hidden tag ids in case old urls with pre-existing hiddenTagIds functionality are used
 * @param {Object} tags
 * @param {number[]} tagIdsToCheck
 * @returns {number[]} only org level tag ids - no global tag ids
 */
export function removeGlobalTagIds(tags, tagIdsToCheck) {
    return tagIdsToCheck.filter((tagId) => !isGlobalTag(tags[tagId]));
}

/**
 * If a tag has permissions of view only then it is a global tag
 * @param {Object} tag
 * @return {boolean}
 */
export function isGlobalTag(tag) {
    return tag.permissions === TAG_PERMISSIONS.VIEW;
}

/**
 * Get the id for a tag
 * @param {Object} tag
 * @returns {number}
 */
export function getTagId(tag) {
    return tag.tagId;
}

/**
 * Get the name of a tag
 * @param {Object} tag
 * @returns {string}
 */
export function getTagName(tag) {
    return tag.tagName;
}


/**
 * Sanitise tags from server. Currently just parses metadata into an object
 * @param {Tag[]} tags
 * @returns {Tag[]}
 */
export function sanitiseTags(tags) {
    return tags.map((tag) => {
        const newTag = { ...tag };
        try {
            newTag.metadata = newTag.metadata ? JSON.parse(newTag.metadata) : null;
            if (newTag.metadata && newTag.metadata.tag_color) {
                newTag.backgroundColor = newTag.metadata.tag_color;
                newTag.color = textColorFromBackground(newTag.metadata.tag_color);
            }
        } catch (error) {
            console.error("Error when parsing tag metadata: ", error);
            sendToRollbar("Error when parsing tag metadata:", error);
            newTag.metadata = null;
        }
        return newTag;
    });
}
