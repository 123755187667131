import * as L from 'leaflet';
import { BLUR_OPACITY } from '../const';
import { TRACK_MARKER_H, TRACK_MARKER_IMAGES, TRACK_MARKER_W } from "../map";
import { forEachObj } from "../utils";

// Initialise svg images
const images = {};
forEachObj(TRACK_MARKER_IMAGES, (path, key) => {
    const img = new Image(TRACK_MARKER_W, TRACK_MARKER_H);
    img.src = path;
    images[key] = img;
});

const TRACK_MARKER_W_DIV_2 = Math.floor(TRACK_MARKER_W / 2);
const TRACK_MARKER_H_DIV_2 = Math.floor(TRACK_MARKER_H / 2);

const MarkerRenderer = L.Canvas.extend({

    updateSvg(layer) {
        if (!this._drawing || layer._empty()) {
            return;
        }

        const p = layer._point;

        if (layer.blurred) {
            // Save the current canvas rendering context
            this._ctx.save();

            this._ctx.globalAlpha = BLUR_OPACITY.VESSEL_OR_EVENT;
            this._ctx.drawImage(images[layer.getType()], p.x - TRACK_MARKER_W_DIV_2, p.y - TRACK_MARKER_H_DIV_2);

            // Revert to the saved context (revert alpha)
            this._ctx.restore();
            return;
        }

        this._ctx.drawImage(images[layer.getType()], p.x - TRACK_MARKER_W_DIV_2, p.y - TRACK_MARKER_H_DIV_2);
    },
});

L.markerRenderer = function shortcut(options) {
    return new MarkerRenderer(options);
};
