// Disable, functions are in line with Leaflet functions
/* eslint-disable func-names */

import * as L from "leaflet";
import "./BasicLayer";
import { VESSEL_PANE, vesselLabelColor } from "../map";
import { VESSEL_ORI_UNKNOWN, VESSEL_VIS_NORMAL } from "../const";

const VesselLayer = L.BasicLayer.extend({

    colorIndex: null,
    labelColor: null,
    nameColor: null,
    nameBgBolor: null,
    offscreenCanvas: null,
    sx: 0,
    sy: 0,
    ori: 0,
    visibility: VESSEL_VIS_NORMAL,
    blurred: false,
    highlighted: false,

    options: {
        pane: VESSEL_PANE,
        interactive: true,
        bubblingMouseEvents: false,
        stroke: false,
        fill: true,
    },

    initialize: function (latLng, colorIndex, renderer, offscreenCanvas, options) {
        L.BasicLayer.prototype.initialize.call(this, latLng, renderer, options);
        this.visibility = options.visibility;
        this.blurred = options.blurred;
        this.highlighted = options.highlighted;
        this.setColorIndex(colorIndex);
        this.country2Index = options.country2Index;
        this.updateSxSy();
        this.offscreenCanvas = offscreenCanvas;
        this.nameColor = options.nameColor;
        this.nameBgColor = options.nameBgColor;
        this.debugHideVesselNames = options.debugHideVesselNames;
    },

    changed: function (colorIndex, visibility, blurred, highlighted) {
        return colorIndex !== this.colorIndex
        || visibility !== this.visibility
        || blurred !== this.blurred
        || highlighted !== this.highlighted;
    },

    setColorIndex: function (colorIndex) {
        this.colorIndex = colorIndex;
        this.labelColor = vesselLabelColor(this.colorIndex);
    },

    updateSxSy: function () {
        this.sx = this.getSx();
        this.sy = this.getSy();
    },

    getOri: function () {
        return this.options.ori === VESSEL_ORI_UNKNOWN ? 0 : Math.floor(this.options.ori - 90);
    },

    updateColorIndex: function (colorIndex) {
        this.setColorIndex(colorIndex);
        this.updateSxSy();
        this.redraw();
    },

    update: function () {
        throw new Error("Must implement update");
    },

    /**
     * Calculate the x coord of where to copy from the offscreen source canvas
     * @return {number}
     */
    getSx: function () {
        throw new Error("Must implement getSx");
    },

    /**
     * Calculate the y coord of where to copy from the offscreen source canvas
     * @return {number}
     */
    getSy: function () {
        throw new Error(`Must implement getSy`);
    },

});

L.vesselLayer = function shortcut(latLng, colorIndex, renderer, offscreenCanvas, options) {
    return new VesselLayer(latLng, colorIndex, renderer, offscreenCanvas, options);
};
L.VesselLayer = VesselLayer;
