import L from "leaflet";
import "./VesselLayer";
import { VESSEL_HEIGHT, VESSEL_WIDTH } from "../map";
import { RETINA_SCALE } from "../../config";
import {
    bigVesselX,
    bigVesselY,
    bigVesselOutlineY,
    bigVesselOutlineXTransshipment,
    bigVesselOutlineX,
    bigVesselOutlineYTransshipment,
} from "../vessel-offscreen-canvas";
import { VESSEL_VIS_AWOL, VESSEL_VIS_AWOL_TRANSSHIPMENT, VESSEL_VIS_HIDDEN } from "../const";

const BigVessel = L.VesselLayer.extend({

    /**
     * Update this layer and redraw
     * @param {Object} param
     * @param {latLng} param.latLng
     * @param {number} param.colorIndex
     * @param {boolean} param.blurred
     * @param {boolean} param.highlighted
     * @param {number} param.visibility
     * @param {number} param.ori
     */
    update({ latLng, colorIndex, blurred, highlighted, visibility, ori }) {
        this._latlng = latLng;
        const changed = this.changed(colorIndex, visibility, blurred, highlighted);
        if (changed) {
            this.visibility = visibility;
            this.blurred = blurred;
            this.highlighted = highlighted;
            this.setColorIndex(colorIndex);
            this.updateSxSy();
        }
        this.options.ori = ori;
        this.redraw();
    },

    // TODO: account for rotation?
    updateBounds() {
        // If the vessel name is hidden, give it 0 bounds so it can't be clicked on
        if (this.visibility === VESSEL_VIS_HIDDEN) {
            this._pxBounds = L.bounds([0, 0], [0, 0]);
            return;
        }

        const t = this._clickTolerance();
        const p = [VESSEL_WIDTH / 2 + t, VESSEL_HEIGHT / 2 + t];
        this._pxBounds = L.bounds(this._point.subtract(p), this._point.add(p));
    },

    updatePath() {
        this._renderer.updateBigVessel(this);
    },

    getSx() {
        if (this.visibility === VESSEL_VIS_AWOL) {
            // Remove 1 here to account for the aliasing on the outline
            return (bigVesselOutlineX(this.colorIndex) - 1) * RETINA_SCALE;
        }
        if (this.visibility === VESSEL_VIS_AWOL_TRANSSHIPMENT) {
            // Remove 1 here to account for the aliasing on the outline
            return (bigVesselOutlineXTransshipment(this.colorIndex) - 1) * RETINA_SCALE;
        }
        return bigVesselX(this.colorIndex) * RETINA_SCALE;
    },

    getSy() {
        if (this.visibility === VESSEL_VIS_AWOL) {
            // Remove 1 here to account for the aliasing on the outline
            return (bigVesselOutlineY(this.country2Index) - 1) * RETINA_SCALE;
        }
        if (this.visibility === VESSEL_VIS_AWOL_TRANSSHIPMENT) {
            // Remove 1 here to account for the aliasing on the outline
            return (bigVesselOutlineYTransshipment(this.country2Index) - 1) * RETINA_SCALE;
        }
        return bigVesselY(this.country2Index) * RETINA_SCALE;
    },

});

// Add a shortcut to the global Leaflet object
L.bigVessel = function shortcut(latLng, colorIndex, renderer, offscreenCanvas, options) {
    return new BigVessel(latLng, colorIndex, renderer, offscreenCanvas, options);
};
L.BigVessel = BigVessel;
