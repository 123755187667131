import { chunkArray } from "./utils";

const DEFAULT_CHUNK_AMOUNT = 5000;
const DEFAULT_LATEST_ONLY = false;

export class BatchRequest {

    /**
     * @param {Object} opts
     * @param {RequestManager} opts.requestManager
     * @param {boolean} opts.latestOnly When a request is made, cancel all existing in-flight requests, i.e. only process the
     *                             latest requests
     * @param {[]} opts.items Items used while making the requests, passed to the queryPartsFunc
     * @param {number} opts.chunkAmount Number of items to include per request
     * @param {function} opts.queryPartsFunc Function to get all the query parts
     * @param {function} opts.reduceResultsFunc Function to reduce/combine the results
     * @param {*} opts.reduceInitialValue Initial value of the reduce/combine function
     */
    constructor({ requestManager, latestOnly, items, chunkAmount, queryPartsFunc, reduceResultsFunc, reduceInitialValue }) {
        this.requestManager = requestManager;
        this.latestOnly = latestOnly ?? DEFAULT_LATEST_ONLY;
        this.items = items;
        this.chunkAmount = chunkAmount ?? DEFAULT_CHUNK_AMOUNT;
        this.queryPartsFunc = queryPartsFunc;
        this.reduceResultsFunc = reduceResultsFunc;
        this.reduceInitialValue = reduceInitialValue;

        // Create a promise and assign the resolve and reject functions as properties of the parent object.
        // More details in Request.js.
        this.requestPromise = new Promise((resolve, reject) => {
            this.deferredResolve = resolve;
            this.deferredReject = reject;
        });

        this.makeRequests();
    }

    async makeRequests() {
        // If latestOnly then cancel all existing requests
        if (this.latestOnly) {
            this.requestManager.cancelAllRequests();
        }

        const chunkedItems = chunkArray(this.items, this.chunkAmount);

        const requests = chunkedItems.map((items) => {
            const queryParts = this.queryPartsFunc(items);
            return this.requestManager.newGraphQLRequest(queryParts);
        });

        // Wait for all the requests to resolve
        this.combinedRequests = await Promise.all(requests.map((request) => request.wait())).then(() => {
            const allResults = requests.map((request) => request.getResult());

            // Glue the results back together
            this.results = allResults.reduce(this.reduceResultsFunc, this.reduceInitialValue ?? []);
        });

        return this.deferredResolve(this.combinedRequests);
    }

    async wait() {
        return this.requestPromise;
    }

    /**
     * Reject the request promise if we're cancelling the loading of this BatchRequest
     * @todo we should probably call cancelAllRequests on the request manager here and not elsewhere
     */
    cancel() {
        return this.deferredReject(new DOMException("BatchRequestCancel"));
    }

    getResult() {
        return this.results;
    }

    awaitResult() {
        return this.wait().then(() => this.getResult());
    }
}
