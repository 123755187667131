import { round } from "./utils";

let c = 0;

// Look for a debugPerformance URL parameter to decide whether or not to output debug info
// We don't use the url.js library here because of dependency issues when using a worker.
let enabled = false;
if (window?.location?.href) {
    enabled = Boolean(new URL(window.location.href).searchParams.get("debugPerformance"));
}

const startTime = {};
const multiple = {};

/**
 * @param {string} name
 * @param {Object} args
 * @param {boolean} args.output
 * @param {string} args.comment
 * @return {number|void}
 */
export function perfStart(name, { output = true, comment = '' } = {}) {
    if (!enabled) { return; }
    c++;
    startTime[c] = {
        ts: performance.now(),
        name: name,
    };
    if (output) {
        console.log("START:", name, `(${c})`, comment); // eslint-disable-line no-console
    }
    return c;
}

/**
 * @param {number} counter
 * @param {Object} args
 * @param {boolean} args.output
 * @param {string} args.comment
 */
export function perfFinish(counter, { output = true, comment = '' } = {}) {
    if (!enabled) { return; }
    const finishTime = performance.now();
    const start = startTime[counter];
    const duration = finishTime - start.ts;
    if (output) {
        console.log(`END: ${start.name} (${counter}) took ${Math.round(duration)} milliseconds.`, comment); // eslint-disable-line no-console
    }
    if (!multiple[start.name]) {
        multiple[start.name] = [];
    }
    multiple[start.name].push(duration);
    delete startTime[counter];
}

// eslint-disable-next-line import/no-unused-modules
export function perfSummarise(key) {
    if (!enabled) { return; }
    if (multiple[key]) {
        const total = multiple[key].reduce((a, b) => (a + b));
        const average = total / multiple[key].length;
        // eslint-disable-next-line no-console
        console.log(`${key} called ${multiple[key].length} times, total time ${round(total)}ms, average time ${round(average, 6)}`);
    } else {
        console.log(key, "not found"); // eslint-disable-line no-console
    }
}
