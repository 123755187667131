import { createSelector } from "reselect";
import VesselFilterBase from "./VesselFilterBase";
import { FILTER_SET_NAMES, OPERATORS } from "../const";
import { getFilterFlagState } from "../../redux/derived-data";
import { emptyObject } from "../utils";

export default class VesselFilterFlagState extends VesselFilterBase {
    name = FILTER_SET_NAMES.FILTER_BY_FLAG_STATE;

    /**
     * fromState is a static factory method for generating and caching VesselFilterFlagState objects
     * based on the current state of the Redux store.
     * @param {Object} state
     * @returns {VesselFilterFlagState|null}
     * @example
     * const filter = VesselFilterFlagState.fromState(state);
     */
    static fromState = createSelector(
        [getFilterFlagState],
        (flagStateFilter) => {
            if (emptyObject(flagStateFilter)) {
                return null;
            }

            return new VesselFilterFlagState(flagStateFilter.flagStates, flagStateFilter.op === OPERATORS.NOT_IN);
        },
    );

    /**
     * @param {string[]} flagStates
     * @param {boolean} exclude
     */
    constructor(flagStates, exclude = false) {
        super(exclude);
        this.flagStates = flagStates;
    }

    /**
     * @returns {{name: string, exclude: boolean, flagStates: string[]}}
     */
    getFilter() {
        return {
            name: this.name,
            exclude: this.exclude,
            vesselFlags: this.flagStates,
        };
    }

    /**
     * @param {Object} data
     * @returns {VesselFilterFlagState}
     */
    static deserialize(data) {
        return new VesselFilterFlagState(data.vesselFlags, Boolean(data.exclude));
    }
}
