import Pool from "./Pool";

export default class VesselMetaPool extends Pool {
    /**
     * Sort vessel ids
     */
    sort() {
        this.items.sort((a, b) => a - b);
    }
}
