import * as L from "leaflet";
import "./VesselLayer";
import { BIG_BUOY_WIDTH_DIV_2 } from "../map";
import { RETINA_SCALE } from "../../config";
import { BIG_BUOY_Y, bigBuoyX } from "../vessel-offscreen-canvas";

const SY = BIG_BUOY_Y * RETINA_SCALE;

const BigBuoy = L.VesselLayer.extend({

    /**
     * Update this layer and redraw
     * @param {Object} opts
     * @param {latLng} opts.latLng
     * @param {number} opts.colorIndex
     * @param {boolean} opts.blurred
     * @param {boolean} opts.visibility
     * @param {boolean} opts.highlighted
     */
    update({ latLng, colorIndex, blurred, visibility, highlighted }) {
        this._latlng = L.latLng(latLng);
        const changed = this.changed(colorIndex, visibility, blurred, highlighted);

        if (changed) {
            this.visibility = visibility;
            this.blurred = blurred;
            this.highlighted = highlighted;
            this.setColorIndex(colorIndex);
            this.updateSxSy();
        }
        this.redraw();
    },

    updateBounds() {
        const t = this._clickTolerance();
        const p = [BIG_BUOY_WIDTH_DIV_2 + t, BIG_BUOY_WIDTH_DIV_2 + t];
        this._pxBounds = new L.Bounds(this._point.subtract(p), this._point.add(p));
    },

    updatePath() {
        this._renderer.updateBigBuoy(this);
    },

    getSx() {
        return bigBuoyX(this.colorIndex) * RETINA_SCALE;
    },

    getSy() {
        return SY;
    },

});

// Add a shortcut to the global Leaflet object
L.bigBuoy = function shortcut(latLng, colorIndex, renderer, offscreenCanvas, options) {
    return new BigBuoy(latLng, colorIndex, renderer, offscreenCanvas, options);
};
L.BigBuoy = BigBuoy;
