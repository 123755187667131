import { createSelector } from "reselect";
import { getFilterAuthorisations } from "../../redux/derived-data";
import { FILTER_SET_NAMES, OPERATORS, REGISTERS, REGISTER_NAMES } from "../const";
import { emptyObject } from "../utils";
import VesselFilterBase from "./VesselFilterBase";

export default class VesselFilterRegister extends VesselFilterBase {
    name = FILTER_SET_NAMES.FILTER_BY_REGISTER;

    /**
     * fromState is a static factory method for generating and caching VesselFilterRegister objects
     * based on the current state of the Redux store.
     * @param {Object} state
     * @returns {VesselFilterRegister[]|null}
     * @example
     * const filter = VesselFilterRegister.fromState(state);
     */
    static fromState = createSelector(
        [getFilterAuthorisations],
        (authorisationsFilter) => {
            if (emptyObject(authorisationsFilter)) {
                return null;
            }

            const vesselFilters = [];
            const registers = Object.values(REGISTERS);

            // All fishing register filters are currently AND relationships
            registers.forEach((register) => {
                if (authorisationsFilter[register]) {
                    const exclude = authorisationsFilter[register] === OPERATORS.NOT_IN;
                    vesselFilters.push(new VesselFilterRegister([REGISTER_NAMES[register]], exclude));
                }
            });

            return vesselFilters;
        },
    );

    /**
     * @param {string[]} registers
     * @param {boolean} exclude
     */
    constructor(registers, exclude = false) {
        super(exclude);
        this.registers = registers;
    }

    /**
     * @returns {{name: string, registers: string[], exclude: boolean}}
     */
    getFilter() {
        return {
            name: this.name,
            registers: this.registers,
            exclude: this.exclude,
        };
    }

    /**
     * @param {Object} data
     * @returns {VesselFilterRegister}
     */
    static deserialize(data) {
        return new VesselFilterRegister(data.registers, Boolean(data.exclude));
    }
}
