import ActivityBase from "./ActivityBase";

export default class ActivityTag extends ActivityBase {
    static name = "ActivityTag";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.tag;
    }

    /**
     * @returns {Object}
     */
    get tag() {
        return this.data.tag;
    }

    /**
     * @returns {string}
     */
    get activityAction() {
        return this.data.activityAction;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivityTag-${this.ts}-${this.tag.tagId}-${this.activityAction}`;
    }
}
