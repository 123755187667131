import { MAX_MEASUREMENT_DISTANCE } from "../config";
import { isInputElem } from "./dom-utils";
import { isKilometers, isKmph, isKnots, isNauticalMiles, isValidDmsLat, isValidDmsLng, unitIsDms } from "./geometry";
import { round, isNumber, clamp } from "./utils";

/**
 * Convert a distance from metres to a different type of unit
 * @param {number} metres
 * @param {string} unit
 * @return {number}
 */
export function convertFromMetres(metres, unit) {
    if (isKilometers(unit)) {
        return round(metres / 1000, 4);
    }

    if (isNauticalMiles(unit)) {
        return round(metres / 1852, 4);
    }
}

/**
 * Convert a distance to metres from a different type of unit
 * @param {number} amount
 * @param {string} unit
 * @return {number}
 */
export function convertToMetres(amount, unit) {
    if (isKilometers(unit)) {
        return amount * 1000;
    }

    if (isNauticalMiles(unit)) {
        return amount * 1852;
    }
}

/**
 * Convert a speed to knots from a different type of unit
 * @param {number} amount
 * @param {string} speedUnit
 * @return {number}
 */
export function convertToKnots(amount, speedUnit) {
    if (!amount || isKnots(speedUnit)) {
        return amount;
    }

    if (isKmph(speedUnit)) {
        return amount / 1.852;
    }
}

/**
 * Convert a speed from knots to a different type of unit
 * @param {number} knots
 * @param {string} speedUnit - The unit that we are converting to
 * @return {number}
 */
export function convertFromKnots(knots, speedUnit) {
    // Nothing to do if the speedUnit we are converting to is already knots
    if (!knots || isKnots(speedUnit)) {
        return knots;
    }

    if (isKmph(speedUnit)) {
        return knots * 1.852;
    }
}

/**
 * Convert seconds to hours
 * @param {number} seconds
 * @returns {number}
 */
export function convertSecondsToHours(seconds) {
    if (!seconds || !isNumber(seconds)) {
        return 0;
    }
    return seconds / 3600;
}

/**
 * Convert hours to seconds
 * @param {number} hours
 * @returns {number}
 */
export function convertHoursToSeconds(hours) {
    if (!hours || !isNumber(hours)) {
        return 0;
    }
    return hours * 3600;
}

export function validLng(value) {
    return value !== "" && isNumber(value) && value >= -180 && value <= 180;
}

export function validLat(value) {
    return value !== "" && isNumber(value) && value >= -90 && value <= 90;
}

/**
 * Is coordinate valid in DMS or Decimal Degrees
 * @param {string|number} coord
 * @param {string} locationUnit
 * @param {boolean} [isLat]
 * @returns {boolean}
 */
export function isValidCoord(coord, locationUnit, isLat = true) {
    if (unitIsDms(locationUnit)) {
        return isLat ? isValidDmsLat(coord) : isValidDmsLng(coord);
    }

    // Unit is decimal degrees
    return isLat ? validLat(coord) : validLng(coord);
}

export function validDistance(value) {
    return value !== "" && isNumber(value) && value <= MAX_MEASUREMENT_DISTANCE && value >= 0;
}

/**
 * Returns true if value is a valid input above 0
 * @param {string} value - numeric string of value
 * @param {object} options
 * @param {boolean} options.allowEmpty - Allow empty speed value
 * @returns {boolean}
 */
export function validPositiveNumber(value, { allowEmpty = false } = {}) {
    if (allowEmpty && value === '') {
        return true;
    }

    // Don't want to allow zero otherwise calculated travel time is infinity.
    return isNumber(value) && value > 0;
}

/**
 * Returns true if all values are valid positive numbers
 * @param  {string[]} values
 * @returns {boolean}
 */
export function validPositiveNumbers(values) {
    return values.every((value) => validPositiveNumber(value));
}

export function sanitiseNumberInput(value) {
    if (value === "-" || value === "" || value === ".") {
        return value;
    }

    return round(value, 5);
}

export function cursorFromAngle(angle) {
    let cursor = "ns-resize";

    if (angle < 22.5) {
        cursor = "ns-resize";
    } else if (angle < 22.5 + 45) {
        cursor = "nesw-resize";
    } else if (angle < 22.5 + 45 * 2) {
        cursor = "ew-resize";
    } else if (angle < 22.5 + 45 * 3) {
        cursor = "nwse-resize";
    } else if (angle < 22.5 + 45 * 4) {
        cursor = "ns-resize";
    } else if (angle < 22.5 + 45 * 5) {
        cursor = "nesw-resize";
    } else if (angle < 22.5 + 45 * 6) {
        cursor = "ew-resize";
    } else if (angle < 22.5 + 45 * 7) {
        cursor = "nwse-resize";
    }

    return cursor;
}

// Directions
const UP = "up";
const DOWN = "down";

// Find the next selected point depending on whether the up or down arrow keys
function findNextSelectedPoint(pointIndex, prevPointIndex, coords, direction) {
    // If a point was just deleted, the arrow keys will jump to the previous selected point index
    if (pointIndex === null && isNumber(prevPointIndex)) {
        // Avoid prevSelectedPointIndex being outside length of coordinates after point deletion
        return clamp(prevPointIndex, 0, coords.length - 1);
    }

    if (direction === UP) {
        return pointIndex === coords.length - 1 ? 0 : pointIndex + 1;
    }

    // Direction must be "down"
    return pointIndex === 0 ? coords.length - 1 : pointIndex - 1;
}

export const polygonKeyboardHandler = (e, {
    isSelected,
    selectedPointIndex,
    prevSelectedPointIndex,
    properties,
    updateFunc,
    removeFunc,
    notify,
    id,
    panelExpanded,
    debouncedTrackEventWithCategory,
}) => {
    if (isInputElem(e.target) || !isSelected) {
        return;
    }

    // Ignore keyboard use if EditAreaPanel isn't expanded.
    if (panelExpanded === false) {
        return;
    }

    // Polygon measurements only use the delete handler for selected points as opposed to the whole measurement
    if (["Delete", "Backspace"].includes(e.key)) {
        if (!isSelected) {
            return;
        }

        // Delete the whole measurement if there isn't a selected point
        if (!isNumber(selectedPointIndex)) {
            removeFunc();
            debouncedTrackEventWithCategory('Deleted via hotkey');
            return;
        }

        const coords = properties.coords;
        if (coords.length < 4) {
            notify("Area must have at least 3 points");
            return;
        }

        updateFunc(id, {
            ...properties,
            coords: coords.slice(0, selectedPointIndex).concat(coords.slice(selectedPointIndex + 1)),
            selectedPointIndex: null,
        });
        debouncedTrackEventWithCategory('Delete selected point via delete key');
        return;
    }

    // Increment selectedPoinIndex
    if (e.key === "ArrowUp") {
        if (isNumber(selectedPointIndex) || selectedPointIndex === null) {
            const coords = properties.coords;
            updateFunc(id, {
                ...properties,
                selectedPointIndex: findNextSelectedPoint(
                    selectedPointIndex,
                    prevSelectedPointIndex,
                    coords,
                    UP,
                ),
            });
            debouncedTrackEventWithCategory('Change selected point via arrow up key');
        }
        return;
    }

    // Decrement selectedPoinIndex
    if (e.key === "ArrowDown") {
        if (isNumber(selectedPointIndex) || selectedPointIndex === null) {
            const coords = properties.coords;
            updateFunc(id, {
                ...properties,
                selectedPointIndex: findNextSelectedPoint(
                    selectedPointIndex,
                    prevSelectedPointIndex,
                    coords,
                    DOWN,
                ),
            });
            debouncedTrackEventWithCategory('Change selected point via arrow down key');
        }
    }
};
