import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatOrgForUi } from "../lib/organisation";

/**
 * HOC to inject necessary props for Manage Orgs components.
 * Matches orgId from the URL to the orgs in state and returns a sanitised organisation for the component.
 * Also provides a linkPrefix for navigation purposes.
 * @param {React.Component} WrappedComponent
 * @returns {React.Component} WithManagedOrg
 */
export function withManagedOrg(WrappedComponent) {

    function getDisplayName(Component) {
        return Component.displayName || Component.name || 'Component';
    }

    function WithManagedOrg() {
        WithManagedOrg.displayName = `WithManagedOrg(${getDisplayName(WrappedComponent)})`;

        const orgId = Number(useParams().orgId);
        const organisations = useSelector((state) => state.organisations);

        let managedOrg = null;

        // Search through all orgs and their groups to find a match
        for (const organisation of organisations) {
            if (organisation.id === orgId) {
                managedOrg = organisation;
                break;
            }
            for (const group of organisation.groups) {
                if (group.id === orgId) {
                    managedOrg = group;
                    break;
                }
            }
        }

        // If we find a match then sanitise the org data, otherwise send null
        const sanitisedOrg = managedOrg ? formatOrgForUi(managedOrg) : null;

        return (
            <WrappedComponent
                organisation={sanitisedOrg}
                linkPrefix={`/manage-organisations/${orgId}`}
            />
        );
    }

    return WithManagedOrg;
}
