import AlertRule from "../AlertRule";
import ActivityBase from "./ActivityBase";

export default class ActivityMultiAlert extends ActivityBase {
    static name = "ActivityMultiAlert";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.alerts.multipleResults;
    }

    /**
     * @returns {number}
     */
    get firstTs() {
        return this.data.firstTs;
    }

    /**
     * @returns {number}
     */
    get lastTs() {
        return this.data.lastTs;
    }

    /**
     * @returns {AlertRule}
     */
    get alertRule() {
        return AlertRule.deserialize(this.data.alertRule);
    }

    /**
     * @returns {number}
     */
    get numVessels() {
        return this.data.numVessels;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivityMultiAlert-${this.alertRule.getId()}-${this.firstTs}-${this.lastTs}`;
    }
}
