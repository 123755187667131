// Reference to the offscreen canvas
import {
    BUOY_RADIUS,
    SMALL_BUOY_STROKE,
    BIG_BUOY_STROKE,
    BIG_BUOY_WIDTH,
    SMALL_VESSEL_OUTLINE_RADIUS,
    SMALL_VESSEL_RADIUS,
    VESSEL_COLOR_OUTLINE,
    VESSEL_COLOR_OUTLINE_SELECTED,
    AIS_COLOR_INFERRED_TRANSSHIPMENT_SELECTED,
    AIS_COLOR_INFERRED_TRANSSHIPMENT,
    getVesselColors,
    VESSEL_HEIGHT,
    VESSEL_OUTLINE_STROKE,
    VESSEL_SPACING,
    VESSEL_WIDTH,
    VESSEL_WIDTH_OUTLINE,
    vesselLabelColor,
    BIG_BUOY_WIDTH_DIV_2,
} from "./map";
import { RETINA_SCALE } from "../config";
import { hexToRgba, modifyRgbRgbaAlpha } from "./utils";
import { getAllCountry2Codes } from "./vessel";
import { urlGet } from "./url";
import { lineLength, degreesToRadians } from "./geometry";

/**
 * Set this directly from the url
 * @type {boolean}
 */
const debugHideVesselNames = Boolean(urlGet("debugHideVesselNames"));

/**
 * The offscreen canvas where we'll render all the vessel sprites
 * {HTMLCanvasElement}
 */
let offscreenCanvas;

// Count of how many vessels have been drawn
let drawnBigVessels = 0;
let drawnBigOutlineVessels = 0;
let drawnBigOutlineVesselsTransshipments = 0;
let drawnSmallVessels = 0;
let drawnSmallOutlineVessels = 0;
let drawnSmallOutlineVesselsTransshipments = 0;
let drawnSmallBuoys = 0;
let drawnBigBuoys = 0;

// Precalculate some constants to speed up calculations
const BIG_VESSEL_OUTLINE_Y = VESSEL_HEIGHT + VESSEL_SPACING;
export const SMALL_VESSEL_Y = VESSEL_SPACING;
export const SMALL_VESSEL_OUTLINE_Y = SMALL_VESSEL_Y + (SMALL_VESSEL_RADIUS * 2) + VESSEL_SPACING;
export const SMALL_VESSEL_OUTLINE_TRANSSHIPMENT_Y = SMALL_VESSEL_OUTLINE_Y + (SMALL_VESSEL_RADIUS * 2) + VESSEL_SPACING;
export const SMALL_BUOY_Y = SMALL_VESSEL_OUTLINE_TRANSSHIPMENT_Y + (SMALL_VESSEL_RADIUS * 2) + VESSEL_SPACING;
export const BIG_BUOY_Y = SMALL_BUOY_Y + (SMALL_VESSEL_RADIUS * 2) + VESSEL_SPACING;
const BIG_VESSEL_Y = BIG_BUOY_Y + (SMALL_VESSEL_RADIUS * 2) + VESSEL_SPACING * 2;
const VESSEL_WIDTH_PLUS_SPACING = VESSEL_WIDTH + VESSEL_SPACING;
const VESSEL_HEIGHT_PLUS_SPACING = VESSEL_HEIGHT + VESSEL_SPACING;
const VESSEL_WIDTH_OUTLINE_PLUS_SPACING = VESSEL_WIDTH_OUTLINE + VESSEL_SPACING;
const VESSEL_HEIGHT_OUTLINE_PLUS_SPACING = BIG_VESSEL_OUTLINE_Y + VESSEL_SPACING + 1;
const SMALL_VESSEL_DIAMETER_PLUS_SPACING = (SMALL_VESSEL_RADIUS * 2) + VESSEL_SPACING;
const BUOY_DIAMETER_PLUS_SPACING = (BIG_BUOY_WIDTH) + VESSEL_SPACING;

/**
 * Returns the offscreen canvas
 * @returns {HTMLCanvasElement}
 */
export function getOffscreenCanvas() {
    return offscreenCanvas;
}

/**
 * Returns x coord of the big vessel sprite based on its position
 * @param {number} n Index of the vessel variation (in this case colour) used to find the x position of the sprite.
 * @returns {number}
 */
export function bigVesselX(n) {
    return VESSEL_SPACING + (n * VESSEL_WIDTH_PLUS_SPACING);
}

/**
 * Returns y coord of the big vessel sprite
 * @param {number} n Index of the vessel variation (in this case country) used to find the y position of the sprite.
 * @returns {number}
 */
export function bigVesselY(n) {
    return BIG_VESSEL_Y + (n * VESSEL_HEIGHT_PLUS_SPACING);
}

/**
 * Returns x coord for vessel outline
 * @param {number} n Index of the vessel variation (in this case colour) used to find the x position of the sprite.
 * @returns {number}
 */
export function bigVesselOutlineX(n) {
    // The vessel outlines are rendered in a second column, dynamically set by the number of colours on the list
    return (getVesselColors().length * (VESSEL_WIDTH + VESSEL_SPACING)) + VESSEL_SPACING + (n * VESSEL_WIDTH_OUTLINE_PLUS_SPACING);
}

/**
 * Returns y coord for vessel outline
 * @param {number} n Index of the vessel variation (in this case colour) used to find the y position of the sprite.
 * @returns {number}
 */
export function bigVesselOutlineY(n) {
    return VESSEL_SPACING + (n * VESSEL_HEIGHT_OUTLINE_PLUS_SPACING);
}

/**
 * Returns x coord for vessel outline
 * @param {number} n Index of the vessel variation (in this case colour) used to find the x position of the sprite.
 * @returns {number}
 */
export function bigVesselOutlineXTransshipment(n) {
    // The vessel outlines are rendered in a third column, dynamically set by the number of colours on the list
    return bigVesselOutlineX(n) + (getVesselColors().length * VESSEL_WIDTH_OUTLINE_PLUS_SPACING);
}

/**
 * Returns y coord for vessel outline
 * @param {number} n Index of the vessel variation (in this case colour) used to find the y position of the sprite.
 * @returns {number}
 */
export function bigVesselOutlineYTransshipment(n) {
    return VESSEL_SPACING + (n * VESSEL_HEIGHT_OUTLINE_PLUS_SPACING);
}

/**
 * Modifies the opacity of vessel fill color
 * @param {string} vesselColor
 * @param {boolean} selected
 * @returns {string}
 */
function getVesselFillStyle(vesselColor, selected) {
    const alpha = selected ? 0.4 : 0.2;

    // Check if it's a hex colour (e.g. #fff or #ffffff)
    if (vesselColor.match(/^#?([0-9A-Fa-f]{3}){1,2}$/)) { // eslint-disable-line security/detect-unsafe-regex
        return hexToRgba(vesselColor, alpha);
    }

    // Otherwise assume we're modifying an rgb or rgba value
    return modifyRgbRgbaAlpha(vesselColor, alpha);
}

/**
 * Returns the fill style for Big Buoys
 * @param {boolean} selected
 * @returns {string} fill color
 */
function getBigBuoyFillStyle(selected) {
    const alpha = selected ? 1 : 0.2;  // I don't see how the value of .4 works for big vessels. They are definitely rendering without any opacity when selected and I can't see where this happens.
    return modifyRgbRgbaAlpha("rgb(0,0,0)", alpha);
}

/**
 * Draw a big vessel outline path onto the canvas
 * @param {number} w
 * @param {number} h
 * @param {number} x
 * @param {number} y
 * @param {*} ctx
 */
function bigVesselOutline(w, h, x, y, ctx) {
    ctx.beginPath();

    // Draw the vessel curvy bit
    ctx.arc(x + h / 2, y + h / 2, h / 2, Math.PI / 2, Math.PI * 1.5);

    // Draw the vessel pointy bits
    ctx.moveTo(x + 6, y);
    ctx.lineTo(x + 15, y);
    ctx.lineTo(x + w, y + h / 2);
    ctx.lineTo(x + 15, y + h);
    ctx.lineTo(x + 6, y + h);
    ctx.moveTo(x + 6, y);
    ctx.closePath();
}

/**
 * Sets canvas context setting for vessel labels
 * @param {CanvasRenderingContext2D} ctx
 * @param {number} vesselColorIndex
 */
function ctxLabelStyles(ctx, vesselColorIndex) {
    ctx.font = "8px RobotoMedium, sans-serif";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = vesselLabelColor(vesselColorIndex);
}

/**
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {string} vesselColor
 * @param {number} vesselColorIndex
 * @param {boolean} selected
 * @param {string} countryCode
 */
function renderBigOutlineVessel(ctx, vesselColor, vesselColorIndex, selected, countryCode, transshipment) {

    // Outlined vessels are drawn at the same width but the stroke is centered
    // so the actual dimensions are represented by VESSEL_WIDTH_OUTLINE and VESSEL_HEIGHT_OUTLINE
    // when copied from the offscreen canvas
    const w = VESSEL_WIDTH;
    const h = VESSEL_HEIGHT;

    let x;
    let y;
    let strokeColor;
    if (transshipment) {
        x = bigVesselOutlineXTransshipment(drawnBigOutlineVesselsTransshipments % getVesselColors().length);
        y = bigVesselOutlineYTransshipment(Math.floor(drawnBigOutlineVesselsTransshipments / getVesselColors().length));
        drawnBigOutlineVesselsTransshipments++;

        strokeColor = selected ? AIS_COLOR_INFERRED_TRANSSHIPMENT_SELECTED : AIS_COLOR_INFERRED_TRANSSHIPMENT;
    } else {
        x = bigVesselOutlineX(drawnBigOutlineVessels % getVesselColors().length);
        y = bigVesselOutlineY(Math.floor(drawnBigOutlineVessels / getVesselColors().length));
        drawnBigOutlineVessels++;

        strokeColor = selected ? VESSEL_COLOR_OUTLINE_SELECTED : VESSEL_COLOR_OUTLINE;
    }

    ctx.save();

    ctx.setLineDash([3, 3]);
    ctx.lineWidth = VESSEL_OUTLINE_STROKE;
    ctx.strokeStyle = strokeColor;
    ctx.lineDashOffset = 1;

    ctx.fillStyle = getVesselFillStyle(vesselColor, selected);

    bigVesselOutline(w, h, x, y, ctx);
    ctx.fill();
    ctx.stroke();

    // Render country code label
    if (!debugHideVesselNames) {
        ctxLabelStyles(ctx, vesselColorIndex);
        ctx.fillText(countryCode, x + 9, y + 7);
    }

    ctx.restore();
}

/**
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {string} vesselColor
 * @param {number} vesselColorIndex
 * @param {string} countryCode
 */
function renderBigVessel(ctx, vesselColor, vesselColorIndex, countryCode) {
    const w = VESSEL_WIDTH;
    const h = VESSEL_HEIGHT;
    const x = bigVesselX(drawnBigVessels % getVesselColors().length);
    const y = bigVesselY(Math.floor(drawnBigVessels / getVesselColors().length));

    ctx.save();
    ctx.fillStyle = vesselColor;
    bigVesselOutline(w, h, x, y, ctx);
    ctx.fill();

    // Render country code label
    if (!debugHideVesselNames) {
        ctxLabelStyles(ctx, vesselColorIndex);
        ctx.fillText(countryCode, x + 9, y + 7);
    }

    ctx.restore();

    drawnBigVessels++;
}

/**
 * Returns the small vessel x coord
 * @param {number} n Index of the vessel variation (in this case colour) used to find the x position of the sprite.
 * @returns {number}
 */
export function smallVesselX(n) {
    return VESSEL_SPACING + (n * SMALL_VESSEL_DIAMETER_PLUS_SPACING);
}

/**
 * Render a small vessel on the passed canvas context
 * @param {CanvasRenderingContext2D} ctx
 * @param {string} vesselColor
 */
function renderSmallVessel(ctx, vesselColor) {
    const r = SMALL_VESSEL_RADIUS;
    const x = smallVesselX(drawnSmallVessels);
    const y = SMALL_VESSEL_Y;

    // Draw a coloured circle
    ctx.fillStyle = vesselColor;
    ctx.beginPath();
    ctx.arc(x + r, y + r, r, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();

    drawnSmallVessels++;
}

/**
 * @param {CanvasRenderingContext2D} ctx
 * @param {string} vesselColor
 * @param {boolean} selected
 */
function renderSmallOutlineVessel(ctx, vesselColor, selected, transshipment) {
    const r = SMALL_VESSEL_RADIUS;

    let x;
    let y;
    let strokeColor;
    if (transshipment) {
        x = smallVesselX(drawnSmallOutlineVesselsTransshipments);
        y = SMALL_VESSEL_OUTLINE_TRANSSHIPMENT_Y;
        drawnSmallOutlineVesselsTransshipments++;

        strokeColor = selected ? AIS_COLOR_INFERRED_TRANSSHIPMENT_SELECTED : AIS_COLOR_INFERRED_TRANSSHIPMENT;
    } else {
        x = smallVesselX(drawnSmallOutlineVessels);
        y = SMALL_VESSEL_OUTLINE_Y;
        drawnSmallOutlineVessels++;

        strokeColor = selected ? VESSEL_COLOR_OUTLINE_SELECTED : VESSEL_COLOR_OUTLINE;
    }

    ctx.save();

    ctx.fillStyle = getVesselFillStyle(vesselColor, selected);
    ctx.beginPath();
    ctx.arc(x + r, y + r, r, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();

    // Stroke radius
    const sR = SMALL_VESSEL_OUTLINE_RADIUS;

    // This is used to alter the center point of the outline
    const radiusDiff = SMALL_VESSEL_RADIUS - SMALL_VESSEL_OUTLINE_RADIUS;

    // We want 4 dashes (and 4 gaps) around the circle, so each line is 1/8 of the circumference
    const dashLength = (2 * Math.PI * sR) / 8;

    // Render the dashed circle
    ctx.setLineDash([dashLength]);
    ctx.lineWidth = 1.5;
    ctx.strokeStyle = strokeColor;
    ctx.lineDashOffset = 0;
    ctx.beginPath();
    ctx.arc(x + sR + radiusDiff, y + sR + radiusDiff, sR, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();

    ctx.restore();

}

/**
 * Get the small buoy sprite x value
 * @param {number} n
 * @returns {number} x
 */
export function smallBuoyX(n) {
    return VESSEL_SPACING + (n * BUOY_DIAMETER_PLUS_SPACING);
}

/**
 * Render a buoy onto the passed canvas context
 * @param {CanvasRenderingContext2D} ctx
 * @param {string} vesselColor
 */
function renderSmallBuoy(ctx, vesselColor) {
    const r = BUOY_RADIUS;
    const x = smallBuoyX(drawnSmallBuoys);
    const y = SMALL_BUOY_Y;

    ctx.strokeStyle = vesselColor;
    ctx.lineWidth = SMALL_BUOY_STROKE;
    ctx.beginPath();
    ctx.arc(x + r, y + r, r, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();

    drawnSmallBuoys++;
}

/**
 * Returns the x coord for buoys
 * @param {number} n Index of the buoy variation (in this case colour) used to find the x position of the sprite.
 * @returns {number}
 */
export function bigBuoyX(n) {
    return VESSEL_SPACING + (n * BUOY_DIAMETER_PLUS_SPACING);
}

/**
 * Render a buoy onto the passed canvas context
 * @param {CanvasRenderingContext2D} ctx
 * @param {string} vesselColor
 * @param {boolean} selected
 */
function renderBigBuoy(ctx, vesselColor, selected) {
    const r = BUOY_RADIUS;
    const c = BIG_BUOY_WIDTH_DIV_2; // Centre point
    const x = bigBuoyX(drawnBigBuoys);
    const y = BIG_BUOY_Y;

    const p1Angle = degreesToRadians(200);
    const p2Angle = degreesToRadians(160);

    // Points on the base circle from which the horizontal roundRect is drawn from
    const p1X = x + c + (r * Math.cos(p1Angle)) - 1;  // -1 because the rounding stunts the rectangle too much.
    const p1Y = y + c + (r * Math.sin(p1Angle));

    const p2X = x + c + (r * Math.cos(p2Angle)) - 1;
    const p2Y = y + c + (r * Math.sin(p2Angle));

    // Width of the horizontal roundRect
    const distance1to2 = lineLength({ x: p1X, y: p1Y }, { x: p2X, y: p2Y });

    const p4Angle = degreesToRadians(245);
    const p5Angle = degreesToRadians(295);

    // Points on the base circle from which the vertical roundRect is drawn from
    const p4X = x + c + (r * Math.cos(p4Angle));
    const p4Y = y + c + (r * Math.sin(p4Angle)) - 2;

    const p5X = x + c + (r * Math.cos(p5Angle));
    const p5Y = y + c + (r * Math.sin(p5Angle)) - 2;

    // Width of the vertical roundRect
    const distance4to5 = lineLength({ x: p4X, y: p4Y }, { x: p5X, y: p5Y });

    ctx.strokeStyle = vesselColor;
    ctx.lineWidth = BIG_BUOY_STROKE;

    // Draw bobble (top piece)
    ctx.fillStyle = getBigBuoyFillStyle(selected);
    ctx.beginPath();
    if (ctx.roundRect) {
        ctx.roundRect(p4X, p4Y, distance4to5, 7, 7);
    }
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    // Save before clip
    ctx.save();

    // Draw circle
    ctx.beginPath();
    ctx.arc(x + c, y + c, r, 0, 2 * Math.PI);
    ctx.closePath();

    // Clip/clear so we overlay circle on the bobble
    ctx.clip();
    ctx.clearRect(x, y, 20, 20);

    // Remove clipping state
    ctx.restore();
    ctx.fill();
    ctx.stroke();

    // Save before clip
    ctx.save();

    // Draw round rect
    ctx.beginPath();
    if (ctx.roundRect) {
        ctx.roundRect(x + 1, p1Y, BIG_BUOY_WIDTH - 2, distance1to2, 15);
    }
    ctx.closePath();

    // Clip/clear so we overlay round rect on circle
    ctx.clip();
    ctx.clearRect(x + 1, p1Y, BIG_BUOY_WIDTH - 2, distance1to2);

    // Remove clipping state
    ctx.restore();
    ctx.fill();
    ctx.stroke();

    drawnBigBuoys++;
}

/**
 * Initialise the offscreen canvas and render all the vessels and buoy sprites onto it
 * @param {boolean} [force=false] force redraw of the canvas
 */
export function initOffscreenCanvas(force = false) {
    if (offscreenCanvas && !force) {
        return;
    }

    const allCountry2Codes = getAllCountry2Codes();

    // Set the sprite dimensions: number of colours by 3 types of vessels (no outline, outline, outline transshipment)
    const canvasWidth = getVesselColors().length * ((VESSEL_WIDTH + VESSEL_SPACING) + (VESSEL_WIDTH_OUTLINE_PLUS_SPACING * 2));

    // Generate the height in case the number of country codes increases
    const canvasHeight = (allCountry2Codes.length * VESSEL_HEIGHT_OUTLINE_PLUS_SPACING) + VESSEL_SPACING;

    offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = RETINA_SCALE * canvasWidth;
    offscreenCanvas.height = RETINA_SCALE * canvasHeight;

    const ctx = offscreenCanvas.getContext('2d');
    if (!ctx) {
        return;
    }

    ctx.scale(RETINA_SCALE, RETINA_SCALE);

    // Render all the big vessels and big outlined vessel sprites with every combination of colour and country code
    drawnBigVessels = 0;
    allCountry2Codes.forEach((code) => {
        getVesselColors().forEach((vesselColor, vesselColorIndex) => {
            renderBigVessel(ctx, vesselColor, vesselColorIndex, code);
        });
    });

    drawnBigOutlineVessels = 0;
    allCountry2Codes.forEach((code) => {
        getVesselColors().forEach((vesselColor, vesselColorIndex) => {
            renderBigOutlineVessel(ctx, vesselColor, vesselColorIndex, Boolean(vesselColorIndex % 2), code, false); // Every second colour is selected
        });
    });

    // needed for outline transshipment vessels (red outline)
    drawnBigOutlineVesselsTransshipments = 0;
    allCountry2Codes.forEach((code) => {
        getVesselColors().forEach((vesselColor, vesselColorIndex) => {
            renderBigOutlineVessel(ctx, vesselColor, vesselColorIndex, Boolean(vesselColorIndex % 2), code, true); // Every second colour is selected
        });
    });

    // Render all the small vessel sprites with each colour
    drawnSmallVessels = 0;
    getVesselColors().forEach((vesselColor) => {
        renderSmallVessel(ctx, vesselColor);
    });

    drawnSmallOutlineVessels = 0;
    getVesselColors().forEach((vesselColor, i) => {
        renderSmallOutlineVessel(ctx, vesselColor, Boolean(i % 2), false);
    });

    // needed for outline transshipment vessels (red outline)
    drawnSmallOutlineVesselsTransshipments = 0;
    getVesselColors().forEach((vesselColor, i) => {
        renderSmallOutlineVessel(ctx, vesselColor, Boolean(i % 2), true);
    });

    // Render all the small buoy sprites
    drawnSmallBuoys = 0;
    getVesselColors().forEach((vesselColor) => {
        renderSmallBuoy(ctx, vesselColor);
    });

    // Render all the big buoy sprites
    drawnBigBuoys = 0;
    getVesselColors().forEach((vesselColor, vesselColorIndex) => {
        renderBigBuoy(ctx, vesselColor, Boolean(vesselColorIndex % 2));
    });

    // Uncomment to show on screen
    // document.body.appendChild(offscreenCanvas);
    // offscreenCanvas.style.top = "200px";
    // offscreenCanvas.style.left = "500px";
    // offscreenCanvas.style.zIndex = 1000;
    // offscreenCanvas.style.position = "absolute";
    // offscreenCanvas.style.width = `${canvasWidth}px`;
    // offscreenCanvas.style.height = `${canvasHeight}px`;
    // offscreenCanvas.style.border = `1px solid red`;
    // offscreenCanvas.style.background = 'grey';
}
