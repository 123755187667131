const recentDispatches = [];
let lastDispatchTs = Date.now();

/**
 * Add given action's type and timestamp to list of recorded actions.
 * @param {String} actionType
 * @param {Object} extraInfo - extra info about the action
 */
export function recordDispatch(actionType, extraInfo) {
    const timeNow = Date.now();

    recentDispatches.push({
        action: {
            type: actionType,
            ...extraInfo,
        },
        timeNow: timeNow,
        msSinceLast: timeNow - lastDispatchTs,
    });

    // Only keep last 50 items
    if (recentDispatches.length > 50) {
        recentDispatches.shift();
    }

    lastDispatchTs = timeNow;
}

/**
 * Get a list of latest dispatched actions
 * @returns {Object[]}
 */
export function getRecentDispatches() {
    return recentDispatches;
}
