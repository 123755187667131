import { utcTs } from "./utils";

export default class CacheMemObj {

    constructor(name, key, { createdIndex, ttl, keyStartTsPos } = {}) {
        this.name = name;
        this.key = key;
        this.cache = {};
        this.createdIndex = Boolean(createdIndex);
        this.ttl = ttl;
        this.keyStartTsPos = keyStartTsPos;
    }

    /**
     * Return the stored item with passed key or undefined if item doesn't exist
     * @param {*} key
     * @return {Promise<*>}
     */
    async get(key) {
        return this.cache[key];
    }

    /**
     * Returns all stored items with passed keys, items that don't exist aren't included
     * @param {*[]} keys
     * @return {Promise<*[]>}
     */
    async getMultiple(keys) {
        const items = [];

        keys.forEach((key) => {
            if (this.cache[key] !== undefined) {
                items.push(this.cache[key]);
            }
        });

        return items;
    }

    /**
     * Add a new item to the cache, if an item with the same key already exists it's overwritten by the newItem
     * @param {*} newItem
     * @param {(string|number)} [key=undefined]
     * @return {Promise<void>}
     */
    async set(newItem, key = undefined) {
        if (this.createdIndex && !newItem._created) {
            newItem._created = utcTs();
        }

        this.cache[key ?? newItem[this.key]] = newItem;
    }

    /**
     * Add multiple items to the cache
     * @param {*[]} items
     * @return {Promise<void>}
     */
    async setMultiple(items) {
        for (const item of items) {
            this.set(item);
        }
    }

    /**
     * Returns all item keys
     * @return {Promise<*[]>}
     */
    async allKeys() {
        return Object.keys(this.cache);
    }

    /**
     * Returns passed keys are that present in the cache
     * @param {*[]} keys
     * @return {Promise<*>}
     */
    async checkMultipleExist(keys) {
        return keys.filter((key) => this.cache[key] !== undefined);
    }

    /**
     * Clears the cache
     * @return {Promise<void>}
     */
    async clear() {
        this.cache = {};
    }

    /**
     * Delete all items with passed keys
     * @param {*[]} keys
     * @returns {Promise<null>}
     */
    async deleteMultiple(keys) {
        if (keys.length === 0) {
            return null;
        }

        for (const key of keys) {
            this.delete(key);
        }
    }

    /**
     * Deleted an item with passed key
     * @param {*} key
     * @returns {Promise<void>}
     */
    async delete(key) {
        delete this.cache[key];
    }

    /**
     * Return all records
     * @return {Promise<[]>}
     */
    async getAll() {
        return Object.values(this.cache);
    }

    /**
     * Return all cache keys
     * @return {Promise<[]>}
     */
    async getAllKeys() {
        return Object.keys(this.cache);
    }

    /**
     * Remove all cache items whose _created entry is less than passed timestamp
     * @param {number} ts
     * @param {number|null} limit
     * @return {Promise<number>}
     */
    async deleteBeforeTs(ts, limit = null) {
        if (!this.createdIndex) {
            throw new Error("Trying to deleteBeforeTs but createdIndex is false");
        }

        // Keep any items whose created_at timestamp is greater than the ttl ts (i.e. remove old items)
        let count = 0;

        for (const item of Object.values(this.cache)) {
            if (limit !== null && count >= limit) {
                break;
            }

            if (item._created < ts) {
                count++;
                this.delete(item[this.key]);
            }
        }

        return count;
    }

    /**
     * @param {number|null} limit
     * @return {Promise<number>}
     */
    async deleteByTtl(limit = null) {
        return this.deleteBeforeTs(utcTs() - this.ttl, limit);
    }
}
