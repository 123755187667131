// Disable, functions are in line with Leaflet functions
/* eslint-disable func-names */
import * as L from 'leaflet';
import throttle from 'lodash.throttle';
import COLORS from '../../styles/shared/colors.module.scss';
import { CAT_MEASUREMENTS, createDebouncedTrackEvent } from '../analytics';
import { notLeftClick } from '../dom-utils';
import {
    angleBetweenPoints,
    circleToLeafBounds,
    leafBoundsOverlap,
    normaliseLng,
} from '../geometry';
import { MEASUREMENTS_PANE } from '../map';
import { cursorFromAngle } from '../measurement';
import MeasurementBase from './MeasurementBase';
import "./MeasurementCircleEdge";

const WEIGHT_SELECTED = 3;
const WEIGHT_UNSELECTED = 1;

const debouncedTrackEvent = createDebouncedTrackEvent(500);

export default class MeasurementCircle extends MeasurementBase {
    constructor({ map, uuid, coords, radius, renderer, onUpdate, onSelect, transLatLng }) {
        super({ map, uuid, renderer, onUpdate, onSelect });
        this.coords = coords;
        this.transLatLng = transLatLng;
        this.radius = radius;
        this.color = COLORS.color__measurement;
        this.circleLayer = null;
        this.circleEdgeLayer = null;
        this.centroidLayer = null;
        this.draggingCentre = false;
        this.draggingEdge = false;
        this.startPoint = null;
        this.startCentre = null;
    }

    /**
     * @returns {L.LatLng}
     */
    get latLng() {
        return this.createLatLng();
    }

    /**
     * Clear cached calculated values for this measurement
     */
    clearCachedValues() {
        this._latLng = null;
    }

    /**
     * Are coords different from those in the the given properties?
     * @param {Object} properties
     * @returns {Boolean}
     */
    coordsChanged(properties) {
        return this.coords[0] !== properties.coords[0] || this.coords[1] !== properties.coords[1];
    }

    /**
     * Is radius different from those in the the given properties?
     * @param {Object} properties
     * @returns {Boolean}
     */
    radiusChanged(properties) {
        return this.radius !== properties.radius;
    }

    /**
     * Do this measurement's properties differ from those in the given object?
     * @param {Object} properties
     * @returns {Boolean}
     */
    propertiesChanged(properties) {
        return this.coordsChanged(properties) || this.radiusChanged(properties);
    }

    /**
     * Update this measurement with new properties and render
     * @param {Object} properties
     */
    setProperties(properties) {
        if (!this.propertiesChanged(properties)) {
            return;
        }

        this.clearCachedValues();
        this.coords = properties.coords;
        this.radius = properties.radius;
        this.draw();
    }

    /**
     * Update the radius for this measurement in external state
     * @param {number} distance
     */
    updateRadius(distance) {
        this.onUpdate(this.uuid, {
            radius: Math.round(distance),
        });
    }

    /**
     * Update the coordinates for this measurement in external state
     * @param {L.LatLng} latLng
     */
    updateLatLng(latLng) {
        const coords = [latLng.lat, normaliseLng(latLng.lng)];

        this.onUpdate(this.uuid, {
            coords: coords,
        });
    }

    /**
     * Is any part of this measurement within the current map bounds?
     * @returns {Boolean}
     */
    isVisible() {
        const leafBounds = circleToLeafBounds(this.latLng, this.radius);
        return leafBoundsOverlap(this.map.getBounds(), leafBounds);
    }

    /**
     * Create and store leaflet layer for this measurement
     * @returns {L.Layer}
     */
    createLayer() {
        if (this._layer) {
            return this._layer;
        }

        // Edge of the circle
        this.circleEdgeLayer = L.measurementCircleEdge(this.latLng, {
            radius: this.radius,
            bubblingMouseEvents: false,
            interactive: true,
            renderer: this.renderer,
            fill: false,
            stroke: true,
            weight: this.selected || this.hovering ? WEIGHT_SELECTED : WEIGHT_UNSELECTED,
            color: this.color,
            opacity: 1,
        });

        // Inside of the circle
        this.circleLayer = L.circle(this.latLng, {
            radius: this.selected ? this.radius : 0,
            bubblingMouseEvents: false,
            interactive: true,
            renderer: this.renderer,
            fill: true,
            fillOpacity: 0.2,
            color: this.color,
            stroke: false,
        });

        // Centre dot
        this.centroidLayer = L.circleMarker(this.latLng, {
            radius: 3,
            bubblingMouseEvents: false,
            interactive: true,
            renderer: this.renderer,
            fillColor: this.color,
            stroke: false,
            fillOpacity: 1,
        });

        this._layer = L.featureGroup([this.centroidLayer, this.circleLayer, this.circleEdgeLayer]);
        this.map.addLayer(this._layer);
        this.addEventListeners();
        return this._layer;
    }

    /**
     * Calculate and store latlng for this measurement circle
     * @returns {L.LatLng}
     */
    createLatLng() {
        if (this._latLng) {
            return this._latLng;
        }

        this._latLng = this.transLatLng({ lat: this.coords[0], lng: this.coords[1] });
        return this._latLng;
    }

    /**
     * Update existing layers to display current state of this measurement
     */
    updateStyles() {
        this.circleEdgeLayer.setStyle({ weight: this.selected || this.hovering ? WEIGHT_SELECTED : WEIGHT_UNSELECTED });
        this.circleEdgeLayer.setRadius(this.radius);
        this.circleEdgeLayer.setLatLng(this.latLng);
        this.circleLayer.setStyle({ fill: this.selected });
        this.circleLayer.setRadius(this.selected ? this.radius : 0);
        this.circleLayer.setLatLng(this.latLng);
        this.centroidLayer.setLatLng(this.latLng);
    }

    /**
     * Reset all state associated with dragging
     */
    clearDragState() {
        this.draggingCentre = false;
        this.draggingEdge = false;
        this.startPoint = null;
        this.startCentre = null;
    }

    /**
     * Move the edge of this measurement circle to a new radius based on given edgeLatLng
     * @param {L.LatLng} edgeLatLng
     */
    moveEdge(edgeLatLng) {
        const distance = this.latLng.distanceTo(edgeLatLng);
        this.updateRadius(distance);
    }

    /**
     * Move the whole measurement circle based on how far the given mouse point is from the start point
     * @param {L.Point} mousePoint
     */
    moveCentre(mousePoint) {
        const difference = {
            x: mousePoint.x - this.startPoint.x,
            y: mousePoint.y - this.startPoint.y,
        };

        const newPoint = {
            x: this.startCentre.x + difference.x,
            y: this.startCentre.y + difference.y,
        };

        const newLatLng = this.map.unproject(newPoint);
        this.updateLatLng(newLatLng);
    }

    onMouseUp(e) {
        super.onMouseUp(e);
        this.map.off({ mousemove: this.handleDrag });
        this.clearDragState();
    }

    onMouseDown(e) {
        super.onMouseDown(e);
        this.map.on({ mousemove: this.handleDrag });
    }

    onDrag(e) {
        if (this.draggingEdge) {
            this.moveEdge(e.latlng);
            debouncedTrackEvent({
                category: CAT_MEASUREMENTS,
                action: 'Change circle radius via drag',
            });
        } else if (this.draggingCentre) {
            this.moveCentre(e.layerPoint);
            debouncedTrackEvent({
                category: CAT_MEASUREMENTS,
                action: 'Move circle via drag',
            });
        }
    }

    onEdgeMouseDown(e) {
        if (notLeftClick(e.originalEvent)) {
            return;
        }
        this.draggingEdge = true;
    }

    onCentreMouseMove() {
        let cursor;

        if (!this.selected) {
            cursor = 'pointer';
        } else if (!this.draggingEdge) {
            cursor = 'move';
        }

        this.map.getPane(MEASUREMENTS_PANE).style.cursor = cursor;
    }

    onCentreMouseDown(e) {
        if (notLeftClick(e.originalEvent)) {
            return;
        }

        this.draggingCentre = true;
        this.startPoint = e.layerPoint;
        this.startCentre = this.map.project(this.latLng);
    }

    onEdgeMouseMove(e) {
        const centroidPxCoord = this.circleLayer._point;
        const mousePxCoord = e.layerPoint;
        const angle = angleBetweenPoints(centroidPxCoord, mousePxCoord);
        let cursor;

        if (!this.selected) {
            cursor = 'pointer';
        } else if (!this.draggingCentre) {
            cursor = cursorFromAngle(angle);
        }

        this.map.getPane(MEASUREMENTS_PANE).style.cursor = cursor;
    }

    handleEdgeMouseDown = (e) => { this.onEdgeMouseDown(e); };

    handleCentreMousedown = (e) => { this.onCentreMouseDown(e); };

    handleDrag = throttle((e) => { this.onDrag(e); }, 16);

    handleCentreMouseMove = throttle(() => { this.onCentreMouseMove(); }, 16);

    handleEdgeMouseMove = throttle((e) => { this.onEdgeMouseMove(e); }, 16);

    addEventListeners() {
        super.addEventListeners();

        this.circleEdgeLayer.on({
            mousemove: this.handleEdgeMouseMove,
            mousedown: this.handleEdgeMouseDown,
        });

        this.circleLayer.on({
            mousemove: this.handleCentreMouseMove,
            mousedown: this.handleCentreMousedown,
        });

        this.centroidLayer.on({
            mousemove: this.handleCentreMouseMove,
            mousedown: this.handleCentreMousedown,
        });
    }
}

// Add a shortcut to the global Leaflet object
L.measurementCircle = function shortcut(options) {
    return new MeasurementCircle(options);
};
