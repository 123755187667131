import { OPERATORS } from "../../const";
import { validPositiveNumber } from "../../measurement";
import { validDistanceRange } from "../../utils";
import { Form } from "./Form";

const EMPTY_DIMENSION_STATE = { value: '', value2: '', op: OPERATORS.GREATER_THAN };

export default class VesselDimensionsForm extends Form {
    /**
     * Creates a new instance of VesselDimensionsForm based on length and width values
     * @param {{ value: string, value2: string, op: string }} length
     * @param {{ value: string, value2: string, op: string }} width
     * @param {boolean} includeMissingDimensions
     * @param {function(VesselDimensionsForm)} updateExternalState
     * @returns {VesselDimensionsForm}
     */
    static fromLengthAndWidth(length, width, includeMissingDimensions, updateExternalState) {
        return new VesselDimensionsForm({
            showingLength: Boolean(length?.value),
            showingWidth: Boolean(width?.value),
            length: length,
            width: width,
            includeMissingDimensions: includeMissingDimensions,
        }, updateExternalState);
    }

    /**
     * @returns {boolean}
     */
    getShowingLength() {
        return Boolean(this._data.showingLength);
    }

    /**
     * @param {boolean} showingLength
     */
    setShowingLength(showingLength) {
        this._data.showingLength = showingLength;

        if (!showingLength) {
            this.clearLength();
        }

        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {boolean}
     */
    getShowingWidth() {
        return Boolean(this._data.showingWidth);
    }

    /**
     * @param {boolean} showingWidth
     */
    setShowingWidth(showingWidth) {
        this._data.showingWidth = showingWidth;

        if (!showingWidth) {
            this.clearWidth();
        }

        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {{ value: string, value2: string, op: string }}
     */
    getLength() {
        return this._data.length ?? EMPTY_DIMENSION_STATE;
    }

    /**
     * Clears the length values
     */
    clearLength() {
        this._data.length = EMPTY_DIMENSION_STATE;
    }

    /**
     * @returns {{ value: string, value2: string, op: string }}
     */
    getWidth() {
        return this._data.width ?? EMPTY_DIMENSION_STATE;
    }

    /**
     * Clears the width values
     */
    clearWidth() {
        this._data.width = EMPTY_DIMENSION_STATE;
    }

    /**
     * @returns {string}
     */
    getLengthValue() {
        return this.getLength().value ?? "";
    }

    /**
     * @param {string} lengthValue
     */
    setLengthValue(lengthValue) {
        this._data.length = { ...this.getLength(), value: lengthValue };
        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {string}
     */
    getLengthValue2() {
        return this.getLength().value2 ?? "";
    }

    /**
     * @param {string} lengthValue2
     */
    setLengthValue2(lengthValue2) {
        this._data.length = { ...this.getLength(), value2: lengthValue2 };
        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {string}
     */
    getLengthOp() {
        return this.getLength().op ?? OPERATORS.GREATER_THAN;
    }

    /**
     * @param {string} lengthOp
     */
    setLengthOp(lengthOp) {
        this._data.length = { ...this.getLength(), op: lengthOp };
        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {string}
     */
    getWidthValue() {
        return this.getWidth().value ?? "";
    }

    /**
     * @param {string} widthValue
     */
    setWidthValue(widthValue) {
        this._data.width = { ...this.getWidth(), value: widthValue };
        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {string}
     */
    getWidthValue2() {
        return this.getWidth().value2 ?? "";
    }

    /**
     * @param {string} widthValue2
     */
    setWidthValue2(widthValue2) {
        this._data.width = { ...this.getWidth(), value2: widthValue2 };
        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {string}
     */
    getWidthOp() {
        return this.getWidth().op ?? OPERATORS.GREATER_THAN;
    }

    /**
     * @param {string} widthOp
     */
    setWidthOp(widthOp) {
        this._data.width = { ...this.getWidth(), op: widthOp };
        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {boolean}
     */
    getIncludeMissingDimensions() {
        return Boolean(this._data.includeMissingDimensions);
    }

    /**
     * @param {boolean} includeMissingDimensions
     */
    setIncludeMissingDimensions(includeMissingDimensions) {
        this._data.includeMissingDimensions = includeMissingDimensions;
        this.updateExternalState();
    }

    /**
     * Validates the form and sets any errors.
     * @param {{ allowEmpty: boolean }} options
     * @returns {boolean} true if valid, otherwise false
     */
    validate({ allowEmpty = false } = {}) {
        const newErrors = {};

        // Need at least length or width to be selected
        if (!allowEmpty && !this.getShowingLength() && !this.getShowingWidth()) {
            newErrors.showingLength = true;
            newErrors.showingWidth = true;
        }

        if (this.getShowingLength()) {
            // When length is selected, first value should always be present
            newErrors.length = !validPositiveNumber(this.getLengthValue(), { allowEmpty });

            if (this.getLengthOp() === OPERATORS.BETWEEN) {
                // When length is selected and operator is BETWEEN, second value should always be present
                newErrors.length2 = !validPositiveNumber(this.getLengthValue2(), { allowEmpty });

                // When length is selected and operator is BETWEEN, first and second values should form a valid range
                if (!validDistanceRange(this.getLengthValue(), this.getLengthValue2(), { allowEmpty })) {
                    newErrors.length = true;
                    newErrors.length2 = true;
                }
            }
        }

        if (this.getShowingWidth()) {
            // When width is selected, first value should always be present
            newErrors.width = !validPositiveNumber(this.getWidthValue(), { allowEmpty });

            if (this.getWidthOp() === OPERATORS.BETWEEN) {
                // When width is selected and operator is BETWEEN, second value should always be present
                newErrors.width2 = !validPositiveNumber(this.getWidthValue2(), { allowEmpty });

                // When width is selected and operator is BETWEEN, first and second values should form a valid range
                if (!validDistanceRange(this.getWidthValue(), this.getWidthValue2(), { allowEmpty })) {
                    newErrors.width = true;
                    newErrors.width2 = true;
                }
            }
        }

        this.setErrors(newErrors);
        return !this.hasErrors();
    }
}
