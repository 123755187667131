import ActivityBase from "./ActivityBase";

export default class ActivityMembership extends ActivityBase {
    static name = "ActivityMembership";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.membership;
    }

    /**
     * @returns {Object}
     */
    get user() {
        return this.data.user;
    }

    /**
     * @returns {Object}
     */
    get updatedBy() {
        return this.data.updatedBy;
    }

    /**
     * @returns {string}
     */
    get activityAction() {
        return this.data.activityAction;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivityMembership-${this.ts}-${this.user.id}-${this.activityAction}`;
    }
}
