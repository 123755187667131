import { EVENT_ECTR, EVENT_ECTS, EVENT_IETS, OPERATORS } from '../../lib/const';
import { arrayUnique, emptyObject } from '../../lib/utils';

export const SET_FILTER_PANEL = 'SET_FILTER_PANEL';
export const SET_FILTER_ACTIVITY = 'SET_FILTER_ACTIVITY';
export const REMOVE_FILTER_ACTIVITY = 'REMOVE_FILTER_ACTIVITY';
export const SET_FILTER_FLAG_STATE = 'SET_FILTER_FLAG_STATE';
export const REMOVE_FILTER_FLAG_STATE = 'REMOVE_FILTER_FLAG_STATE';
export const SET_FILTER_VESSEL_TYPE = 'SET_FILTER_VESSEL_TYPE';
export const REMOVE_FILTER_VESSEL_TYPE = 'REMOVE_FILTER_VESSEL_TYPE';
export const SET_FILTER_AUTHORISATIONS = 'SET_FILTER_AUTHORISATIONS';
export const REMOVE_FILTER_AUTHORISATIONS = 'REMOVE_FILTER_AUTHORISATIONS';
export const SET_FILTER_RISK_INDICATORS = 'SET_FILTER_RISK_INDICATORS';
export const SET_FILTER_NOTEWORTHY_RISK_INDICATORS = 'SET_FILTER_NOTEWORTHY_RISK_INDICATORS';
export const REMOVE_FILTER_RISK_INDICATORS = 'REMOVE_FILTER_RISK_INDICATORS';
export const REMOVE_FILTER_NOTEWORTHY_RISK_INDICATORS = 'REMOVE_FILTER_NOTEWORTHY_RISK_INDICATORS';
export const REMOVE_FILTER_RISK_INDICATOR = 'REMOVE_FILTER_RISK_INDICATOR';
export const REMOVE_FILTER_NOTEWORTHY_RISK_INDICATOR = 'REMOVE_FILTER_NOTEWORTHY_RISK_INDICATOR';
export const SET_FILTER_TAGS = 'SET_FILTER_TAGS';
export const REMOVE_FILTER_TAGS = 'REMOVE_FILTER_TAGS';
export const REMOVE_FILTER_TAG = 'REMOVE_FILTER_TAG';
export const ADD_NOTEWORTHY_TAG_TO_FILTER = 'ADD_NOTEWORTHY_TAG_TO_FILTER';
export const ADD_NOTEWORTHY_RISK_INDICATOR_TO_FILTER = 'ADD_NOTEWORTHY_RISK_INDICATOR_TO_FILTER';
export const REMOVE_FILTER_NOTEWORTHY_TAGS = 'REMOVE_FILTER_NOTEWORTHY_TAGS';
export const REMOVE_FILTER_NOTEWORTHY_TAG = 'REMOVE_FILTER_NOTEWORTHY_TAG';
export const SET_FILTER_NOTEWORTHY_TAGS = 'SET_FILTER_NOTEWORTHY_TAGS';
export const ADD_NOTEWORTHY_ACTIVITY_TO_FILTER = 'ADD_NOTEWORTHY_ACTIVITY_TO_FILTER';
export const REMOVE_FILTER_NOTEWORTHY_ACTIVITY = 'REMOVE_FILTER_NOTEWORTHY_ACTIVITY';
export const SET_FILTER_NOTEWORTHY_ACTIVITY = 'SET_FILTER_NOTEWORTHY_ACTIVITY';
export const REMOVE_FILTER_PAST_PORTS = 'REMOVE_FILTER_PAST_PORTS';
export const SET_FILTER_PAST_PORTS = 'SET_FILTER_PAST_PORTS';
export const SET_FILTER_DIMENSIONS = 'SET_FILTER_DIMENSIONS';
export const REMOVE_FILTER_DIMENSIONS = 'REMOVE_FILTER_DIMENSIONS';

/**
 * Clean the activities array to remove encounters if an encounter subtype is selected
 * @param {Object[]} activities
 */
function cleanActivities(activities) {
    if (activities.includes(EVENT_ECTS) || activities.includes(EVENT_IETS)) {
        return activities.filter((activityId) => activityId !== EVENT_ECTR);
    }

    return activities;
}

export const initialState = {
    filterPanel: null,

    /**
     * filterActivity
     * @example {
     *    op: 'OR',
     *    activities: ['FISH', 'ANOM', 'SPED'],
     *    speedOptions: {
     *       op: 'LESS_THAN',
     *       value: 12,
     *       includePortAreas: true,
     *    },
     * }
     */
    filterActivity: {},

    /**
     * filterNoteworthyActivity
     * @example {
     *    op: 'AND',
     *    noteworthyActivities: ['FISH', 'ANOM'],
     * }
     */
    filterNoteworthyActivity: {},

    /**
     * filterFlagState
     * @example {
     *    op: 'OR',
     *    flagStates: ['TF.AD'],
     * }
     */
    filterFlagState: {},

    /**
     * filterVesselType
     * @example {
     *     op: 'NOT_IN',
     *     vesselTypes: [33, 34],
     * }
     */
    filterVesselType: {},

    /**
     * filterAuthorisations
     * @example {
     *     'SPRFMO': 'IN',
     *     'WCPFC': 'IN',
     *     'subfilters': {
     *         'WCPFC': {
     *             'IS': [
     *                 'authorisedToTransshipHighseas'
     *             ]
     *         }
     *     }
     * }
     */
    filterAuthorisations: {},

    /**
     * filterTags
     * @example {
     *     op: 'OR',
     *     selectedTagIds: [33, 34],
     * }
     */
    filterTags: {},

    /**
     * filterRiskIndicators
     * @example {
     *     op: 'OR',
     *     riskIndicators: [{
     *          vbaType: RISK_INDICATORS.LONG_TIME_SEA,
     *          riskLevel: RISK_LEVELS.LOW
     *      }, {...}],
     * }
     */
    filterRiskIndicators: {},

    /**
     * filterNoteworthyTags
     * @example {
     *     op: 'AND',
     *     noteworthyTagIds: [33, 34],
     * }
     */
    filterNoteworthyTags: {},

    /**
     * filterNoteworthyRiskIndicators
     * @example {
     *     op: 'AND',
     *     riskIndicators: [{
     *          vbaType: RISK_INDICATORS.LONG_TIME_SEA,
     *          riskLevel: RISK_LEVELS.LOW
     *      }, {...}],
     * }
     */
    filterNoteworthyRiskIndicators: {},

    /**
     * filterPastPorts
     * @example {
     *    op: 'OR',
     *    countries: ['NZD'],
     *    lastPortOnly: true,
     *    timeRangeDays: 30,
     * }
     */
    filterPastPorts: {},

    /**
     * filterDimensions
     * @example {
     *    length: { value: 10, value2: 20, op: 'BETWEEN' },
     *    width: { value: 5, value2: 8, op: 'BETWEEN' },
     * }
     */
    filterDimensions: {},
};

export function vesselFiltersReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FILTER_PANEL: {
            return {
                ...state,
                filterPanel: action.panel,
            };
        }
        case SET_FILTER_ACTIVITY: {
            return {
                ...state,
                filterActivity: {
                    op: action.op,
                    activities: cleanActivities(action.activities),
                    speedOptions: action.speedOptions,
                },
            };
        }
        case REMOVE_FILTER_ACTIVITY: {
            return {
                ...state,
                filterActivity: {},
            };
        }
        case REMOVE_FILTER_NOTEWORTHY_ACTIVITY: {
            return {
                ...state,
                filterNoteworthyActivity: {},
            };
        }
        case SET_FILTER_NOTEWORTHY_ACTIVITY: {
            return {
                ...state,
                filterNoteworthyActivity: {
                    op: OPERATORS.AND,
                    noteworthyActivities: cleanActivities(action.noteworthyActivities),
                    speedOptions: action.speedOptions,
                },
            };
        }
        case ADD_NOTEWORTHY_ACTIVITY_TO_FILTER: {
            // If activity already exists in noteworthy activity filter, ignore
            if (!emptyObject(state.filterNoteworthyActivity) &&
                state.filterNoteworthyActivity.noteworthyActivities.includes(action.activity)
            ) {
                return state;
            }

            // If activity already exists in regular activity filter, ignore
            if (!emptyObject(state.filterActivity) &&
                state.filterActivity.op === OPERATORS.AND &&
                state.filterActivity.activities.includes(action.activity)
            ) {
                return state;
            }

            if (emptyObject(state.filterActivity) && emptyObject(state.filterNoteworthyActivity)) {
                // Not currently filtering by activity, so just start a regular activity filter
                return {
                    ...state,
                    filterActivity: {
                        op: OPERATORS.AND,
                        activities: [action.activity],
                        speedOptions: action.speedOptions,
                    },
                };
            }

            if (state.filterActivity.op === OPERATORS.AND || state.filterActivity.activities?.length === 1) {
                // Filtering by AND, so can add this activity to existing activity filter
                return {
                    ...state,
                    filterActivity: {
                        ...state.filterActivity,
                        op: OPERATORS.AND,
                        activities: cleanActivities(
                            arrayUnique(state.filterActivity.activities.concat(action.activity)),
                        ),
                    },
                };
            }

            // Filtering by OR, so we need to add this activity to a special extra activity filter
            // so we can filter by [a OR b] AND [c]
            return {
                ...state,
                filterNoteworthyActivity: {
                    ...state.filterNoteworthyActivity,
                    noteworthyActivities: cleanActivities(
                        arrayUnique((state.filterNoteworthyActivity.noteworthyActivities ?? []).concat(action.activity)),
                    ),
                },
            };
        }
        case SET_FILTER_FLAG_STATE: {
            return {
                ...state,
                filterFlagState: {
                    flagStates: action.flagStates,
                    op: action.op,
                },
            };
        }
        case REMOVE_FILTER_FLAG_STATE: {
            return {
                ...state,
                filterFlagState: {},
            };
        }
        case SET_FILTER_VESSEL_TYPE: {
            return {
                ...state,
                filterVesselType: {
                    vesselTypes: action.vesselTypes,
                    op: action.op,
                },
            };
        }
        case REMOVE_FILTER_VESSEL_TYPE: {
            return {
                ...state,
                filterVesselType: {},
            };
        }
        case SET_FILTER_AUTHORISATIONS: {
            return {
                ...state,
                filterAuthorisations: action.authorisations,
            };
        }
        case REMOVE_FILTER_AUTHORISATIONS: {
            return {
                ...state,
                filterAuthorisations: {},
            };
        }
        case SET_FILTER_TAGS: {
            return {
                ...state,
                filterTags: {
                    op: action.op,
                    selectedTagIds: action.selectedTagIds,
                },
            };
        }
        case REMOVE_FILTER_TAGS: {
            return {
                ...state,
                filterTags: {},
            };
        }
        case REMOVE_FILTER_TAG: {
            return {
                ...state,
                filterTags: {
                    op: state.filterTags.op,
                    selectedTagIds: state.filterTags.selectedTagIds.filter((tagId) => tagId !== action.tagId),
                },
            };
        }
        case SET_FILTER_NOTEWORTHY_TAGS: {
            return {
                ...state,
                filterNoteworthyTags: {
                    op: OPERATORS.AND,
                    noteworthyTagIds: action.noteworthyTagIds,
                },
            };
        }
        case ADD_NOTEWORTHY_TAG_TO_FILTER: {
            // If tag already exists in noteworthy tags filter, ignore
            if (!emptyObject(state.filterNoteworthyTags) &&
                state.filterNoteworthyTags.noteworthyTagIds.includes(action.tagId)
            ) {
                return state;
            }

            // If tag already exists in regular tags filter, ignore
            if (!emptyObject(state.filterTags) &&
                state.filterTags.op === OPERATORS.AND &&
                state.filterTags.selectedTagIds.includes(action.tagId)
            ) {
                return state;
            }

            if (emptyObject(state.filterTags) && emptyObject(state.filterNoteworthyTags)) {
                // Not currently filtering by tags, so just start a regular tags filter
                return {
                    ...state,
                    filterTags: {
                        op: OPERATORS.AND,
                        selectedTagIds: [action.tagId],
                    },
                };
            }

            if (state.filterTags.op === OPERATORS.AND || state.filterTags.selectedTagIds?.length === 1) {
                // Filtering by AND, so can add this tag to existing tags filter
                return {
                    ...state,
                    filterTags: {
                        op: OPERATORS.AND,
                        selectedTagIds: arrayUnique(state.filterTags.selectedTagIds.concat(action.tagId)),
                    },
                };
            }

            // Filtering by OR, so we need to add this tag to a special extra tag filter
            // so we can filter by [a OR b] AND [c]
            return {
                ...state,
                filterNoteworthyTags: {
                    ...state.filterNoteworthyTags,
                    noteworthyTagIds: arrayUnique((state.filterNoteworthyTags.noteworthyTagIds ?? []).concat(action.tagId)),
                },
            };
        }
        case ADD_NOTEWORTHY_RISK_INDICATOR_TO_FILTER: {
            // If risk indicator already exists in noteworthy risk indicator filter, ignore
            if (!emptyObject(state.filterNoteworthyRiskIndicators) &&
                state.filterNoteworthyRiskIndicators.riskIndicators.find((risk) => risk.vbaType === action.indicator.vbaType && risk.riskLevel === action.indicator.riskLevel)
            ) {
                return state;
            }

            // If risk indicator already exists in regular risk indicator filter, ignore
            if (!emptyObject(state.filterRiskIndicators) &&
                state.filterRiskIndicators.op === OPERATORS.AND &&
                state.filterRiskIndicators.riskIndicators.find((risk) => risk.vbaType === action.indicator.vbaType && risk.riskLevel === action.indicator.riskLevel)
            ) {
                return state;
            }

            if (emptyObject(state.filterRiskIndicators) && emptyObject(state.filterNoteworthyRiskIndicators)) {
                // Not currently filtering by risk indicators, so just start a regular risk indicators filter
                return {
                    ...state,
                    filterRiskIndicators: {
                        op: OPERATORS.AND,
                        riskIndicators: [action.indicator],
                    },
                };
            }

            if (state.filterRiskIndicators.op === OPERATORS.AND || state.filterRiskIndicators.riskIndicators?.length === 1) {
                // Filtering by AND, so can add this risk indicator to existing risk indicators filter
                return {
                    ...state,
                    filterRiskIndicators: {
                        op: OPERATORS.AND,
                        riskIndicators: state.filterRiskIndicators.riskIndicators.filter((risk) => {
                            return risk.vbaType !== action.indicator.vbaType || risk.riskLevel !== action.indicator.riskLevel;
                        }).concat(action.indicator),
                    },
                };
            }

            // Filtering by OR, so we need to add this risk indicator to a special extra risk indicators filter
            // so we can filter by [a OR b] AND [c]
            return {
                ...state,
                filterNoteworthyRiskIndicators: {
                    ...state.filterNoteworthyRiskIndicators,
                    op: OPERATORS.AND,
                    riskIndicators: (state.filterNoteworthyRiskIndicators.riskIndicators ?? []).filter((risk) => {
                        return risk.vbaType !== action.indicator.vbaType || risk.riskLevel !== action.indicator.riskLevel;
                    }).concat(action.indicator),
                },
            };
        }
        case REMOVE_FILTER_NOTEWORTHY_TAGS: {
            return {
                ...state,
                filterNoteworthyTags: {},
            };
        }
        case REMOVE_FILTER_NOTEWORTHY_TAG: {
            return {
                ...state,
                filterNoteworthyTags: {
                    op: action.op,
                    noteworthyTagIds: state.filterNoteworthyTags.noteworthyTagIds.filter((tagId) => tagId !== action.tagId),
                },
            };
        }
        case SET_FILTER_PAST_PORTS: {
            return {
                ...state,
                filterPastPorts: {
                    countries: action.countries,
                    lastPortOnly: action.lastPortOnly,
                    op: action.op,
                    timeRangeDays: action.timeRangeDays,
                },
            };
        }
        case REMOVE_FILTER_PAST_PORTS: {
            return {
                ...state,
                filterPastPorts: {},
            };
        }
        case SET_FILTER_RISK_INDICATORS: {
            if (action.riskIndicators === 0) {
                return {
                    ...state,
                    filterRiskIndicators: {},
                };
            }

            return {
                ...state,
                filterRiskIndicators: {
                    op: action.op,
                    riskIndicators: action.riskIndicators,
                },
            };
        }
        case SET_FILTER_NOTEWORTHY_RISK_INDICATORS: {
            if (action.riskIndicators === 0) {
                return {
                    ...state,
                    filterNoteworthyRiskIndicators: {},
                };
            }

            return {
                ...state,
                filterNoteworthyRiskIndicators: {
                    op: action.op,
                    riskIndicators: action.riskIndicators,
                },
            };
        }
        case REMOVE_FILTER_RISK_INDICATORS: {
            return {
                ...state,
                filterRiskIndicators: {},
            };
        }
        case REMOVE_FILTER_NOTEWORTHY_RISK_INDICATORS: {
            return {
                ...state,
                filterNoteworthyRiskIndicators: {},
            };
        }
        case REMOVE_FILTER_RISK_INDICATOR: {
            const newRiskIndicators = state.filterRiskIndicators.riskIndicators.filter(
                (indicator) => indicator.vbaType !== action.indicator.vbaType || indicator.riskLevel !== action.indicator.riskLevel,
            );

            if (newRiskIndicators.length === 0) {
                return {
                    ...state,
                    filterRiskIndicators: {},
                };
            }

            return {
                ...state,
                filterRiskIndicators: {
                    op: state.filterRiskIndicators.op,
                    riskIndicators: newRiskIndicators,
                },
            };
        }
        case REMOVE_FILTER_NOTEWORTHY_RISK_INDICATOR: {
            const newRiskIndicators = state.filterNoteworthyRiskIndicators.riskIndicators.filter(
                (indicator) => indicator.vbaType !== action.indicator.vbaType || indicator.riskLevel !== action.indicator.riskLevel,
            );

            if (newRiskIndicators.length === 0) {
                return {
                    ...state,
                    filterNoteworthyRiskIndicators: {},
                };
            }

            return {
                ...state,
                filterNoteworthyRiskIndicators: {
                    op: state.filterRiskIndicators.op,
                    riskIndicators: newRiskIndicators,
                },
            };
        }
        case SET_FILTER_DIMENSIONS: {
            return {
                ...state,
                filterDimensions: action.dimensions,
            };
        }
        case REMOVE_FILTER_DIMENSIONS: {
            return {
                ...state,
                filterDimensions: {},
            };
        }
        default:
            return state;
    }
}

//
// Action creators
//
export function addNoteworthyActivityToFilter(activity) {
    return {
        type: ADD_NOTEWORTHY_ACTIVITY_TO_FILTER,
        activity: activity,
    };
}

export function removeFilterNoteworthyActivity() {
    return {
        type: REMOVE_FILTER_NOTEWORTHY_ACTIVITY,
    };
}

export function setFilterNoteworthyActivity(noteworthyActivities, speedOptions) {
    return {
        type: SET_FILTER_NOTEWORTHY_ACTIVITY,
        noteworthyActivities: noteworthyActivities,
        speedOptions: speedOptions,
    };
}

export function addNoteworthyTagToFilter(tagId) {
    return {
        type: ADD_NOTEWORTHY_TAG_TO_FILTER,
        tagId: tagId,
    };
}

export function removeFilterNoteworthyTags() {
    return {
        type: REMOVE_FILTER_NOTEWORTHY_TAGS,
    };
}

export function removeFilterNoteworthyTag(tagId) {
    return {
        type: REMOVE_FILTER_NOTEWORTHY_TAG,
        tagId: tagId,
    };
}

export function setFilterNoteworthyTags(noteworthyTagIds) {
    return (dispatch, getState) => {
        // sanitise filter by existing tags
        const validTags = Object.keys(getState().tags.tags);
        noteworthyTagIds = noteworthyTagIds.filter((tagId) => validTags.includes(tagId.toString()));

        dispatch({
            type: SET_FILTER_NOTEWORTHY_TAGS,
            noteworthyTagIds: noteworthyTagIds,
        });
    };
}


export function addNoteworthyRiskIndicatorToFilter(indicator) {
    return {
        type: ADD_NOTEWORTHY_RISK_INDICATOR_TO_FILTER,
        indicator: indicator,
    };
}

export function removeFilterVesselType() {
    return {
        type: REMOVE_FILTER_VESSEL_TYPE,
    };
}

export function removeFilterTag(tagId) {
    return {
        type: REMOVE_FILTER_TAG,
        tagId: tagId,
    };
}

export function setFilterVesselType(op, vesselTypes) {
    return {
        type: SET_FILTER_VESSEL_TYPE,
        op: op,
        vesselTypes: vesselTypes,
    };
}

export function removeFilterFlagState() {
    return {
        type: REMOVE_FILTER_FLAG_STATE,
    };
}

export function setFilterFlagState(op, flagStates) {
    return {
        type: SET_FILTER_FLAG_STATE,
        op: op,
        flagStates: flagStates,
    };
}

export function removeFilterActivity() {
    return {
        type: REMOVE_FILTER_ACTIVITY,
    };
}

export function setFilterActivity(op, activities, speedOptions = {}) {
    return {
        type: SET_FILTER_ACTIVITY,
        op: op,
        activities: activities,
        speedOptions: speedOptions,
    };
}

export function setFilterAuthorisations(authorisations) {
    return {
        type: SET_FILTER_AUTHORISATIONS,
        authorisations: authorisations,
    };
}

/**
 * Like `set` but replace new filters and keep old ones unrelated to new
 * @param {Object} newAuthorisations
 */
export function addToFilterAuthorisations(newAuthorisations) {
    return (dispatch, getState) => {
        dispatch(setFilterAuthorisations({ ...getState().vesselFilters.filterAuthorisations, ...newAuthorisations }));
    };
}

export function removeFilterAuthorisations() {
    return {
        type: REMOVE_FILTER_AUTHORISATIONS,
    };
}

export function setFilterTags(op, selectedTagIds) {
    return (dispatch, getState) => {
        // sanitise filter by existing tags
        const validTags = Object.keys(getState().tags.tags);
        selectedTagIds = selectedTagIds.filter((selectedTagId) => validTags.includes(selectedTagId.toString()));

        dispatch({
            type: SET_FILTER_TAGS,
            op: op,
            selectedTagIds: selectedTagIds,
        });
    };
}

export function removeFilterTags() {
    return {
        type: REMOVE_FILTER_TAGS,
    };
}

export function setFilterPanel(panel) {
    return {
        type: SET_FILTER_PANEL,
        panel: panel,
    };
}

export function removeFilterPastPorts() {
    return {
        type: REMOVE_FILTER_PAST_PORTS,
    };
}

export function setFilterPastPorts(op, countries, lastPortOnly, timeRangeDays) {
    return {
        type: SET_FILTER_PAST_PORTS,
        op: op,
        countries: countries,
        lastPortOnly: lastPortOnly,
        timeRangeDays: timeRangeDays,
    };
}

export function setFilterRiskIndicators(riskIndicators, op) {
    return {
        type: SET_FILTER_RISK_INDICATORS,
        riskIndicators: riskIndicators,
        op: op,
    };
}

export function setFilterNoteworthyRiskIndicators(riskIndicators, op) {
    return {
        type: SET_FILTER_NOTEWORTHY_RISK_INDICATORS,
        riskIndicators: riskIndicators,
        op: op,
    };
}

export function removeFilterRiskIndicators() {
    return {
        type: REMOVE_FILTER_RISK_INDICATORS,
    };
}

export function removeFilterNoteworthyRiskIndicators() {
    return {
        type: REMOVE_FILTER_NOTEWORTHY_RISK_INDICATORS,
    };
}

export function removeFilterRiskIndicator(indicator) {
    return {
        type: REMOVE_FILTER_RISK_INDICATOR,
        indicator: indicator,
    };
}

export function removeFilterNotworthyRiskIndicator(indicator) {
    return {
        type: REMOVE_FILTER_NOTEWORTHY_RISK_INDICATOR,
        indicator: indicator,
    };
}

export function setFilterDimensions(dimensions) {
    return {
        type: SET_FILTER_DIMENSIONS,
        dimensions: dimensions,
    };
}

export function removeFilterDimensions() {
    return {
        type: REMOVE_FILTER_DIMENSIONS,
    };
}
