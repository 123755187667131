import { HELP_ID } from "./const";
import { isCanary, isDevelopment, isStaging } from "./env";
import { orgIsGroup } from "./organisation";

let enabled = false;
let eventTrackingEnabled = false;

export function initIntercom(user) {
    if (enabled) { return; }

    // Don't init Intercom if we don't have an Intercom Hmac (i.e. dev server)
    if (!user.intercomHmacSha256) {
        return;
    }
    enabled = true;

    const companies = [];
    user.roles.forEach((role) => {
        companies.push({
            id: role.organisation.id,
            name: role.organisation.name,
            group: orgIsGroup(role.organisation),
        });
    });

    window.Intercom('boot', {
        app_id: 'hm494219',
        email: user.email,
        user_id: user.id,
        user_hash: user.intercomHmacSha256,
        custom_launcher_selector: `.${HELP_ID}`,
        hide_default_launcher: true,
        companies: companies,

        // User only belongs to groups (no subscribed org)
        group_trialist: user.roles.length > 0 && user.roles.every((role) => orgIsGroup(role.organisation)),
    });
}

/**
 * Enable event tracking for Intercom
 */
export function initIntercomEventTracking() {
    eventTrackingEnabled = true;
}

/**
 * @param {string} eventName
 * @param {{}} metadata
 */
export function intercomTrackEvent(eventName, metadata = {}) {
    if (!enabled || !eventTrackingEnabled) { return; }
    window.Intercom('trackEvent', eventName, metadata);
}

export function shutDownIntercom() {
    if (window.Intercom) {
        window.Intercom('shutdown');
    }
}

/**
 * Display intercom popup with prepopulated text.
 * @param {string} prePopulatedText
 */
export function intercomShowMessage(prePopulatedText = "") {
    if (!enabled) {
        if (isDevelopment() || (isStaging() && !isCanary())) {
            // eslint-disable-next-line no-alert
            alert(`Intercom isn't available on dev servers, so imagine an intercom popup saying:\n\n${prePopulatedText}`);
        }
        return;
    }
    window.Intercom('showNewMessage', prePopulatedText);
}
