/* eslint-disable */
import * as L from "leaflet";
import { flipPositions, splitPolygon, translatePoints } from "../geometry";

export const SbGeoJSON = L.GeoJSON.extend({

    /**
     * Because of the way Leaflet is written, there's no elegant way to override how features are turned into layers.
     * For now, we're just going to have to deal with a direct copy and paste of this addData function. I've commented
     * all the lines I've changed. :paaaaaaainface:
     * @param {{}} geojson
     * @returns {*|SbGeoJSON}
     */
    addData: function (geojson) {
        // Changed `isArray` to `Array.isArray` :painface:
        var features = Array.isArray(geojson) ? geojson : geojson.features,
            i, len, feature;

        if (features) {
            for (i = 0, len = features.length; i < len; i++) {
                // only add this if geometry or geometries are set and not null
                feature = features[i];
                if (feature.geometries || feature.geometry || feature.features || feature.coordinates) {
                    this.addData(feature);
                }
            }
            return this;
        }

        var options = this.options;

        if (options.filter && !options.filter(geojson)) { return this; }

        // Changed from `geometryToLayer` to `this.geomToLayer` :painface:
        var layer = this.geomToLayer(geojson, options);
        if (!layer) {
            return this;
        }
        // Changed from `asFeature` to `L.GeoJSON.asFeature` :painface:
        layer.feature = L.GeoJSON.asFeature(geojson);

        layer.defaultOptions = layer.options;
        this.resetStyle(layer);

        if (options.onEachFeature) {
            options.onEachFeature(geojson, layer);
        }

        return this.addLayer(layer);
    },

    /**
     * Split and translate any polygon or multi polygons, so they don't wrap around the world.
     * For other geojson features, use the built-in Leaflet processing
     * @param {object} geojson
     * @param {object} options
     * @returns {*|null}
     */
    geomToLayer: function(geojson, options) {
        const geometry = geojson.type === 'Feature' ? geojson.geometry : geojson;

        if (['Polygon', 'MultiPolygon'].includes(geometry.type)) {
            let coords = geometry ? geometry.coordinates : null;
            if (!coords && !geometry) {
                return null;
            }

            // Flip lng/lat to lat/lng
            coords = flipPositions(coords);

            // Split and translate the points
            const polygons = splitPolygon(coords, this.options.westernBoundary)
                .map((polygon) => translatePoints(polygon, this.options.translateDistance, this.options.westernBoundary));

            return new L.Polygon(polygons, options);
        }

        // TODO: need to give Polylines a similar treatment
        return L.GeoJSON.geometryToLayer(geojson, options);
    },
});

L.sbGeoJson = function shortcut(geojson, options) {
    return new SbGeoJSON(geojson, options);
};
L.SbGeoJSON = SbGeoJSON;
