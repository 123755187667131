/*
* All rights reserved. Use is subject to License terms.
* See LICENSE.TXT in the root directory of this source tree.
*/
import React from 'react';
import 'leaflet/dist/leaflet.css';
import Map from '../Map';
import Ui from '../Ui';
import Keyboard from "../Keyboard";
import BrowserEvents from '../BrowserEvents';
import MapDataLoaderLink from '../MapDataLoader';
import MainLayoutLink from '../layouts/MainLayoutLink';
import MapCover from "../MapCover";
import { useHtmlTitle } from '../../hooks';
import FileDropzone from "../FileDropzone";

export default function GlobalMap() {
    useHtmlTitle("Map");

    return (
        <MainLayoutLink extraClass="main-layout--map" analysisControls>
            <div className="map-container">
                <FileDropzone />
                <MapCover />
                <MapDataLoaderLink />
                <Map />
                <Ui />
                <Keyboard />
                <BrowserEvents />
            </div>
        </MainLayoutLink>
    );
}
