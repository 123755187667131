import ActivityBase from "./ActivityBase";

export default class ActivityVesselTaggingNote extends ActivityBase {
    static name = "ActivityVesselTaggingNote";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.vesselTaggingNote;
    }

    /**
     * @returns {Object}
     */
    get vesselTagging() {
        return this.data.vesselTagging;
    }

    /**
     * @returns {Object}
     */
    get vesselProfile() {
        return this.data.vesselProfile;
    }

    /**
     * @returns {Object}
     */
    get tag() {
        return this.data.tag;
    }

    /**
     * @returns {string}
     */
    get activityAction() {
        return this.data.activityAction;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivityVesselTaggingNote-${this.ts}-${this.vesselTagging.vesselTaggingId}`;
    }
}
