import ActivityBase from "./ActivityBase";

export default class ActivityUserAoi extends ActivityBase {
    static name = "ActivityUserAoi";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.userAois;
    }

    /**
     * @returns {Object}
     */
    get userAoi() {
        return this.data.userAoiActivity;
    }

    /**
     * @returns {Object}
     */
    get updatedFields() {
        return this.data.updatedFields;
    }

    /**
     * @returns {string}
     */
    get activityAction() {
        return this.data.activityAction;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivityUserAoi-${this.ts}-${this.userAoi.aoiId}-${this.activityAction}`;
    }
}
