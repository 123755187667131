import { notLeftClick } from "../dom-utils";
import { MEASUREMENTS_PANE } from "../map";

export default class MeasurementBase {
    constructor({ map, uuid, renderer, onUpdate, onSelect }) {
        this._layer = null;
        this.map = map;
        this.uuid = uuid;
        this.renderer = renderer;
        this.selected = false;
        this.dragging = false;
        this.hovering = false;
        this.onUpdate = onUpdate;
        this.onSelect = onSelect;
    }

    get layer() {
        return this.createLayer();
    }

    createLayer() {
        throw new Error('createLayer needs to be extended.');
    }

    setProperties() {
        throw new Error('setProperties needs to be extended.');
    }

    isVisible() {
        throw new Error('isVisible needs to be extended.');
    }

    // No-op, extend to use.
    updateStyles() {}

    // No-op, extend to use.
    clearCachedValues() {}

    /**
     * Set selected state on the measurement and render.
     */
    select() {
        if (this.selected) {
            return;
        }

        this.selected = true;
        this.draw();
    }

    /**
     * Remove selected state from measurement and render.
     */
    deselect() {
        if (!this.selected) {
            return;
        }

        this.selected = false;
        this.draw();
    }

    /**
     * Render the measurement on the map, or remove from map if it shouldn't be visible.
     */
    draw() {
        if (this.isVisible()) {
            this.createLayer();
            this.updateStyles();
        } else {
            this.removeFromMap();
        }
    }

    /**
     * Remove measurement layer from the map + remove map event handlers and cursor state
     */
    removeFromMap() {
        if (this._layer) {
            this.removeCursorState();
            this.removeEventListeners();
            this.map.removeLayer(this._layer);
            this._layer = null;
        }
        this.clearCachedValues();
    }

    /**
     * Enable map dragging if we're no longer hovering over or dragging measurement
     */
    maybeEnableMapDragging() {
        if (!this.hovering && !this.dragging) {
            this.enableMapDragging();
        }
    }

    /**
     * Enable map dragging
     */
    enableMapDragging() {
        this.map.getPane(MEASUREMENTS_PANE).style.removeProperty("cursor");
        this.map.dragging.enable();
    }

    /**
     * Disable map dragging
     */
    disableMapDragging() {
        this.map.dragging.disable();
    }

    onMouseUp() {
        if (!this.dragging) {
            return;
        }

        this.dragging = false;
        this.maybeEnableMapDragging();
    }

    onMouseDown(e) {
        if (notLeftClick(e.originalEvent)) {
            return;
        }

        this.dragging = true;
        this.hovering = true;
        this.disableMapDragging();
        this.onSelect(this.uuid);
    }

    onMouseOver() {
        this.hovering = true;
        this.disableMapDragging();
        this.updateStyles();
    }

    onMouseOut() {
        this.hovering = false;
        this.maybeEnableMapDragging();
        this.updateStyles();
    }

    handleMouseUp = (e) => {
        this.onMouseUp(e);
    };

    handleMouseDown = (e) => {
        this.onMouseDown(e);
    };

    handleMouseOver = (e) => {
        this.onMouseOver(e);
    };

    handleMouseOut = (e) => {
        this.onMouseOut(e);
    };

    addEventListeners() {
        this._layer.on({
            mousedown: this.handleMouseDown,
            mouseover: this.handleMouseOver,
            mouseout: this.handleMouseOut,
        });
        this.map.on({
            mouseup: this.handleMouseUp,
        });
    }

    removeEventListeners() {
        this._layer.off({
            mousedown: this.handleMouseDown,
            mouseover: this.handleMouseOver,
            mouseout: this.handleMouseOut,
        });
        this.map.off({
            mouseup: this.handleMouseUp,
        });
    }

    removeCursorState() {
        this.onMouseUp();
        this.hovering = false;
        this.enableMapDragging();
    }
}
