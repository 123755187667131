import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '../pages/ErrorPage';

// We need to use a class component here because there's no hook alternative to componentDidCatch
// yet: https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
class RootErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidMount() {
        window.addEventListener("unhandledrejection", this.onUnhandledRejection);
    }

    componentDidCatch(error) {
        // Display fallback UI
        this.setState({ hasError: true });

        if (this.props.rollbar) {
            this.logErrorToRollbar(error);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("unhandledrejection", this.onUnhandledRejection);
    }

    onUnhandledRejection = (event) => {
        // Ignore google analytics or intercom unhandled rejections e.g. when they're
        // blocked by ad blockers
        if (event.reason?.stack?.search(/(www\.googletagmanager\.com)|(widget\.intercom\.io)/) !== -1) {
            return;
        }

        event.promise.catch((error) => {
            this.componentDidCatch(error);
        });
    };

    logErrorToRollbar(error) {
        this.props.rollbar.error(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage />;
        }
        return this.props.children;
    }
}

RootErrorBoundary.defaultProps = {
    rollbar: null,
};

RootErrorBoundary.propTypes = {
    rollbar: PropTypes.object,
    children: PropTypes.node.isRequired,
};

export default RootErrorBoundary;
