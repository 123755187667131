import * as L from "leaflet";
import "./BasicLayer";

const MeasurementPolygonPlusIcon = L.BasicLayer.extend({

    initialize(latLng, w, h, renderer, options) {
        L.BasicLayer.prototype.initialize.call(this, latLng, renderer, options);
        this.w = w;
        this.h = h;
        this.hidden = true;
    },

    /**
     * Used for click detection and clipping (I think)
     */
    updateBounds() {
        const t = this._clickTolerance();
        const p = [this.w + t, this.h + t];
        this._pxBounds = new L.Bounds(this._point.subtract(p), this._point.add(p));
    },

    updatePath() {
        this._renderer.updateCreatePointIcon(this);
    },

    setHidden(isHidden) {
        this.hidden = isHidden;
        this.redraw();
    },

    /**
     * Update this layer and redraw
     * @param latLng
     */
    setLatLng(latLng) {
        this._latlng = L.latLng(latLng);
        this.redraw();
    },

    getWidth() {
        return this.w;
    },

    getHeight() {
        return this.h;
    },
});

// Add a shortcut to the global Leaflet object
L.measurementPolygonPlusIcon = function shortcut(latLng, w, h, renderer, options) {
    return new MeasurementPolygonPlusIcon(latLng, w, h, renderer, options);
};
