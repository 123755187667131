import { connect } from "react-redux";
import { clearForm } from "../../redux/slices/forms";
import VesselDimensionsFormUi from "./VesselDimensionsFormUi";

export default connect(
    null,
    {
        clearForm,
    },
)(VesselDimensionsFormUi);
