import * as L from "leaflet";
import "./VesselLayer";
import { BUOY_RADIUS } from "../map";
import { RETINA_SCALE } from "../../config";
import { SMALL_BUOY_Y, smallBuoyX } from "../vessel-offscreen-canvas";

const SY = SMALL_BUOY_Y * RETINA_SCALE;

const SmallBuoy = L.VesselLayer.extend({

    changed(colorIndex, blurred) {
        return colorIndex !== this.colorIndex || blurred !== this.blurred;
    },

    /**
     * Update this layer and redraw
     * @param {object} param
     * @param {latLng} param.latLng
     * @param {number} param.colorIndex
     * @param {boolean} param.blurred
     * @param {boolean} param.visibility
     * @param {boolean} param.highlighted
    */
    update({ latLng, colorIndex, blurred, visibility, highlighted }) {
        this._latlng = L.latLng(latLng);
        const changed = this.changed(colorIndex, visibility, blurred, highlighted);

        if (changed) {
            this.visibility = visibility;
            this.blurred = blurred;
            this.highlighted = highlighted;
            this.setColorIndex(colorIndex);
            this.updateSxSy();
        }
        this.redraw();
    },

    updateBounds() {
        const t = this._clickTolerance();
        const p = [BUOY_RADIUS + t, BUOY_RADIUS + t];
        this._pxBounds = new L.Bounds(this._point.subtract(p), this._point.add(p));
    },

    updatePath() {
        this._renderer.updateSmallBuoy(this);
    },

    getSx() {
        return smallBuoyX(this.colorIndex) * RETINA_SCALE;
    },

    getSy() {
        return SY;
    },

});

// Add a shortcut to the global Leaflet object
L.smallBuoy = function shortcut(latLng, colorIndex, renderer, offscreenCanvas, options) {
    return new SmallBuoy(latLng, colorIndex, renderer, offscreenCanvas, options);
};
L.SmallBuoy = SmallBuoy;
