export default class VesselFilterBase {

    constructor(exclude = false) {
        this.exclude = exclude;
    }

    getFilter() { // eslint-disable-line class-methods-use-this
        throw new Error('Must implement getFilter');
    }

    /**
     * If an object has a toJSON method it determines what is serialized when using JSON.stringify
     * More info: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
     * @returns {Object}
     */
    toJSON() {
        return this.getFilter();
    }

    static fromJson() {
        throw new Error('Must implement fromJson');
    }
}
