import React, { Fragment } from "react";
import { vesselName } from "../vessel";
import { simpleCapitalize, simplePlural } from "../grammar";
import { ALERT_TYPE_ENTR, ALERT_TYPE_ENTR_FOREIGN, ALERT_TYPE_EXIT, OPERATORS, OPERATOR_LABELS, UNITS_NM } from "../const";
import TagsList from "../../components/TagsList";
import { convertSecondsToHours, convertFromKnots } from "../measurement";
import { formatDateFromTimestamp } from "../timeutils";
import { getSpeedUnit } from '../geometry';
import { round } from "../utils";
import SpeedOptionsAdapter from "./SpeedOptionsAdapter";

export default class AlertTextBuilder {
    /**
     * @param {AlertRule} alertRule
     * @param {Object} opts
     * @param {boolean} opts.plainText
     * @param {boolean} opts.capitalize
     * @param {string} opts.distanceUnit
     */
    constructor(alertRule, { plainText = false, capitalize = false, distanceUnit = UNITS_NM } = {}) {
        this.alertRule = alertRule;
        this.plainText = plainText;
        this.capitalize = capitalize;
        this.enterOrEnters = "enters";
        this.exitOrExits = "exits";
        this.stopOrStops = "stops";
        this.distanceUnit = distanceUnit;
        this.parts = [];
    }

    /**
     * @returns {number}
     */
    getPartsCount() {
        return this.parts.length;
    }

    /**
     * Returns true if text should be capitalized.
     * @returns {boolean}
     */
    shouldCapitalize() {
        return this.capitalize && this.getPartsCount() === 0;
    }

    /**
     * Returns true if text is being placed in the middle of a sentence.
     * @returns {boolean}
     */
    isMidSentence() {
        return !this.shouldCapitalize();
    }

    /**
     * Add info about selected vessels. Does not include vessel attributes.
     * E.g. "Any vessel", "SUNFLOWER or 2 others", "Vessels"
     * @returns {void}
     */
    addSelectedVesselsInfo() {
        if (this.alertRule.filteredByAnyVessels()) {
            this.parts.push(`${simpleCapitalize("any", this.shouldCapitalize())} vessel`);
            return;
        }

        if (this.alertRule.filteredBySelectedVessels()) {
            const selectedVessels = this.alertRule.getSelectedVessels();
            const firstVessel = selectedVessels[0];
            const numOthers = selectedVessels.length - 1;
            this.parts.push(`${vesselName(firstVessel)}${(numOthers > 0 ? ` or ${numOthers} other${simplePlural(numOthers)}` : "")}`);

            if (numOthers > 1) {
                this.enterOrEnters = "enter";
                this.exitOrExits = "exit";
                this.stopOrStops = "stop";
            }
            return;
        }

        this.parts.push(simpleCapitalize("vessels", this.shouldCapitalize()));
        this.enterOrEnters = "enter";
        this.exitOrExits = "exit";
        this.stopOrStops = "stop";
    }

    /**
     * Get tags info in plain text
     * @returns {string}
     */
    getPlainTextTagInfo() {
        const tags = this.alertRule.getTags();
        const trimmedTags = tags.slice(0, 2);
        const numOthers = tags.length - trimmedTags.length;
        let text = `${simpleCapitalize("tagged", this.shouldCapitalize())} with `;

        if (this.alertRule.getTags().length > 1) {
            text += `${OPERATOR_LABELS[this.alertRule.getTagsOp()].toLowerCase()} of `;
        }

        text += trimmedTags.map((tag) => `‘${tag.tagName}’`).join(", ");

        if (numOthers > 0) {
            text += ` + ${numOthers} other${simplePlural(numOthers)}`;
        }

        return text;
    }

    /**
     * Get tags info in JSX (tags are displayed as pills)
     * @returns {JSX.Element}
     */
    getJsxTagInfo() {
        return <>
            <span className="u__mr--025">
                {simpleCapitalize("tagged", this.shouldCapitalize())} with
                {this.alertRule.getTags().length > 1 ? ` ${OPERATOR_LABELS[this.alertRule.getTagsOp()].toLowerCase()} of` : ""}
            </span>
            <TagsList tags={this.alertRule.getTags()} extraClass="pills--inline" />
        </>;
    }

    /**
     * Add info about any vessel attributes filters that have been applied.
     * @returns {void}
     */
    addAttributeFilterInfo() {
        if (this.alertRule.filteredByTaggedVessels()) {
            this.parts.push(this.plainText ? this.getPlainTextTagInfo() : this.getJsxTagInfo());
            return;
        }

        if (this.alertRule.filteredByVesselDimensions()) {
            const dimensions = this.alertRule.getDimensionsFilterOptions();

            // Only show "with" if we're mid sentence e.g. in activity feed: "2 vessels with length over 100m entered NZ EEZ"
            // but in track history: "Length over 100m entered NZ EEZ"
            let text = `${this.isMidSentence() ? "with " : ""}`;

            if (dimensions.length.value) {
                text += `length ${OPERATOR_LABELS[dimensions.length.op]} ${dimensions.length.value}m` +
                    `${dimensions.length.op === OPERATORS.BETWEEN ? `–${dimensions.length.value2}m` : ''}`;
            }

            if (dimensions.length.value && dimensions.width.value) {
                text += " and ";
            }

            if (dimensions.width.value) {
                text += `width ${OPERATOR_LABELS[dimensions.width.op]} ${dimensions.width.value}m` +
                    `${dimensions.width.op === OPERATORS.BETWEEN ? `–${dimensions.width.value2}m` : ''}`;
            }

            text += ` (${dimensions.includeMissingDimensions ? "including" : "excluding"} vessels with missing dimensions)`;
            this.parts.push(simpleCapitalize(text, this.shouldCapitalize()));
        }
    }

    /**
     * Add info about what should trigger the alert. e.g. "Enters New Zealand EEZ from a foreign port"
     * @returns {void}
     */
    addTriggerInfo() {
        if (this.alertRule.triggeredByGeofence()) {
            const type = this.alertRule.getGeofenceType();
            const aoiTitle = this.alertRule.getGeofenceAreaName();

            switch (type) {
                case ALERT_TYPE_ENTR:
                    this.parts.push(`${simpleCapitalize(this.enterOrEnters, this.shouldCapitalize())} ‘${aoiTitle}’`);
                    return;
                case ALERT_TYPE_ENTR_FOREIGN:
                    this.parts.push(`${simpleCapitalize(this.enterOrEnters, this.shouldCapitalize())} ‘${aoiTitle}’ from a foreign port`);
                    return;
                case ALERT_TYPE_EXIT:
                    this.parts.push(`${simpleCapitalize(this.exitOrExits, this.shouldCapitalize())} ‘${aoiTitle}’`);
                    return;
                default:
                    throw new Error(`Invalid geofence type ${type}`);
            }
        }

        if (this.alertRule.triggeredByMissingAis()) {
            const numHours = convertSecondsToHours(this.alertRule.getMissingAisSeconds());
            this.parts.push(`${simpleCapitalize(this.stopOrStops, this.shouldCapitalize())} transmitting AIS for more than ${numHours} hour${simplePlural(numHours)}`);
            return;
        }

        if (this.alertRule.triggeredBySpeedInArea()) {
            this.parts.push(`${simpleCapitalize("travels", this.shouldCapitalize())} ${this.speedInAreaText(this.alertRule.getSpeedInAreaFilterSpeedOptions())}`);
            return;
        }

        this.parts.push("(missing alert information)");
    }

    /**
     * Add info about what triggered the alert. e.g. "Entered ‘New Zealand EEZ’"
     * @param {Object} alertTriggeredMetaData
     * @returns {void}
     */
    addTriggeredInfo(alertTriggeredMetaData = null) {
        if (this.alertRule.triggeredByGeofence()) {
            const type = this.alertRule.getGeofenceType();
            const aoiTitle = this.alertRule.getGeofenceAreaName();

            switch (type) {
                case ALERT_TYPE_ENTR:
                    this.parts.push(`${simpleCapitalize("entered", this.shouldCapitalize())} ‘${aoiTitle}’`);
                    return;
                case ALERT_TYPE_ENTR_FOREIGN:
                    this.parts.push(`${simpleCapitalize("entered", this.shouldCapitalize())} ‘${aoiTitle}’ from a foreign port`);
                    return;
                case ALERT_TYPE_EXIT:
                    this.parts.push(`${simpleCapitalize("exited", this.shouldCapitalize())} ‘${aoiTitle}’`);
                    return;
                default:
                    throw new Error("Invalid geofence type");
            }
        }

        if (this.alertRule.triggeredByMissingAis()) {
            if (alertTriggeredMetaData && alertTriggeredMetaData?.lastPos) {
                const numHours = convertSecondsToHours(this.alertRule.getMissingAisSeconds());
                this.parts.push(`${simpleCapitalize("stopped", this.shouldCapitalize())} transmitting AIS on ${formatDateFromTimestamp(alertTriggeredMetaData.lastPos.ts, {
                    includeYear: true,
                })} (more than ${numHours} hour AIS data gap)`);
                return;
            }

            const numHours = convertSecondsToHours(this.alertRule.getMissingAisSeconds());
            this.parts.push(`${simpleCapitalize("stopped", this.shouldCapitalize())} transmitting AIS for more than ${numHours} hour${simplePlural(numHours)}`);
            return;
        }

        if (this.alertRule.triggeredBySpeedInArea()) {
            this.parts.push(`${simpleCapitalize("travelled", this.shouldCapitalize())} ${this.speedInAreaText(this.alertRule.getSpeedInAreaFilterSpeedOptions())}`);
            return;
        }
        this.parts.push("(missing alert information)");
    }

    /**
     * Get the final text after building it.
     * @returns {string|JSX.Element}
     */
    getText() {
        return this.plainText ? this.parts.join(" ") : <>{this.parts.map((part, i) => <Fragment key={i}>{part} </Fragment>)}</>;
    }

    /**
     * Get speed in area text from speed options.
     * @param {SpeedOptionsFilterSet} speedOptions
     * @returns {string|JSX.Element}
     */
    speedInAreaText(speedOptions) {
        const op = SpeedOptionsAdapter.opFromFilterSet(speedOptions, this.distanceUnit);
        const minSpeed = round(convertFromKnots(speedOptions.minSpeed, getSpeedUnit(this.distanceUnit)), 1);
        const maxSpeed = round(convertFromKnots(speedOptions.maxSpeed, getSpeedUnit(this.distanceUnit)), 1);
        let text = `${OPERATOR_LABELS[op]} `;

        if (op === OPERATORS.BETWEEN) {
            text += `${minSpeed}–${maxSpeed}`;
        } else if (op === OPERATORS.LESS_THAN) {
            text += `${maxSpeed}`;
        } else {
            text += `${minSpeed}`;
        }

        text += `${getSpeedUnit(this.distanceUnit)} in ‘${this.alertRule.getSpeedInAreaAoiName()}’`;
        text += ` (${speedOptions.includePortAreas ? "including" : "excluding"} ports and anchorages)`;

        return text;
    }
}
