import { LOGIN_PATH } from "../config";
import { shutDownIntercom } from "./intercom";

/**
 * Immediately redirect the browser to the login page with the current path specified as the return path
 */
export function redirectToLogin() {
    shutDownIntercom();
    window.location.href = `${LOGIN_PATH}?r=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
}
