export default class ActivityBase {
    constructor(params) {
        this._params = params;
    }

    /**
     * @returns {number}
     */
    get ts() {
        return this._params.ts;
    }

    /**
     * @returns {string}
     */
    get activityType() {
        return this._params.activityType;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        throw new Error("get uuid() must be implemented by subclass");
    }
}
