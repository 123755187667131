// Disable, functions are in line with Leaflet functions
/* eslint-disable func-names */
import * as L from 'leaflet';
import { BLUR_OPACITY } from '../const';
import { LAYER_TYPE_TRACK, lineColor, lineWeight } from "../map";
import { isAGap, isAnAnomaly, isAnEncounter, isAnExcursion, isAwol, isFishing, isLoitering, isInferredTranshipment, isTransshipment } from "../vessel-utils";

const Segment = L.Polyline.extend({

    layerType: LAYER_TYPE_TRACK,

    options: {
        // Leaflet options
        interactive: true,
        bubblingMouseEvents: false,

        // Whether the segment is selected
        selected: false,
        blurred: false,
        flags: null,

        // Flags
        fishing: false,
        buoy: false,
        awol: false,
        gap: false,
        encounter: false,
        loitering: false,
        anomaly: false,
        inferredTransshipment: false,
        transshipment: false,

        // We're already simplifying the line with skimming, when rendering large amount of tracks
        // smoothing can actually slow the Leaflet part of the rendering process down.
        smoothFactor: 0,
        opacity: 1,
    },

    initialize: function (latLngs, options) {
        // Call parent initialize function
        L.Polyline.prototype.initialize.call(this, latLngs, options);

        // Set options from flags
        this.options.fishing = isFishing(this.options.flags);
        this.options.anomaly = isAnAnomaly(this.options.flags);
        this.options.awol = isAwol(this.options.flags);
        this.options.gap = isAGap(this.options.flags);
        this.options.encounter = isAnEncounter(this.options.flags);
        this.options.inferredTransshipment = isInferredTranshipment(this.options.flags);
        this.options.transshipment = isTransshipment(this.options.flags);
        this.options.loitering = isLoitering(this.options.flags);
        this._initialUpdate();
    },

    /**
     * Update style properties that can change interactively (e.g. when a segment is selected)
     * @private
     */
    _calcStyles: function () {
        this.options.color = lineColor(this.options);

        // Selected tracks can be blurred during rendering. Hovering over a blurred track hicklights it.
        if (this.options.highlighted) {
            this.options.opacity = BLUR_OPACITY.HIGHLIGHTED_TRACK;
        } else {
            this.options.opacity = this.options.selected && this.options.blurred ? BLUR_OPACITY.TRACK : 1;
        }

        // passing this option so we can debug overlapping encounters with transshipments
        // TODO delete transshipment option once debug is done
        this.options.weight = lineWeight({ selected: this.options.selected, isTransshipment: this.options.transshipment });
    },

    /**
     * Update all style properties that are calculated once when initialized
     * @private
     */
    _initialUpdate: function () {
        this._calcStyles();
        if (isAwol(this.options.flags) || isAGap(this.options.flags) || isAnExcursion(this.options.flags)) {
            this.options.dashArray = [1, 5]; // Setting as array means canvas renderer doesn't have to use split
            this.options.dashOffset = '0';
        }
    },

    /**
     * Update the layer styles
     * @private
     */
    _refreshStyle: function () {
        if (this._renderer) {
            this._renderer._updateStyle(this);
        }
    },

    _calcAndRefreshStyle: function () {
        this._calcStyles();
        this._refreshStyle();
    },

    select: function () {
        this.options.selected = true;
        this._calcAndRefreshStyle();
    },

    deSelect: function () {
        this.options.selected = false;
        this._calcAndRefreshStyle();
    },

    // Mouseover callback - adds a highlight to a selected but blurred track
    mouseOver: function () {
        if (this.options.blurred) {
            this.options.highlighted = true;
            this._calcAndRefreshStyle();
        }
    },

    // Removes highlight
    mouseOut: function () {
        if (this.options.highlighted) {
            this.options.highlighted = false;
            this._calcAndRefreshStyle();
        }
    },
});

// Add a shortcut to the global Leaflet object
L.segment = function shortcut(latlngs, options) {
    return new Segment(latlngs, options);
};
L.Segment = Segment;
