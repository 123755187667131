export class Form {
    /**
     * @param {Object} data
     * @param {function} updateExternalState
     */
    constructor(data, updateExternalState) {
        this._data = data;
        this._updateExternalState = updateExternalState;
    }

    /**
     * Serialize the form to a plain js object. This should be used when storing
     * it in the Redux store.
     * @returns {Object}
     */
    serialize() {
        return { ...this._data };
    }

    /**
     * Deserialize a plain js object to create a new instance of the form.
     * @param {Object} data
     * @param {function} updateExternalState
     * @returns {Form}
     */
    static deserialize(data, updateExternalState) {
        return new this(data, updateExternalState);
    }

    /**
     * Get the key used to identify the form in the Redux store.
     * @returns {string}
     */
    getKey() {
        return this._data.key;
    }

    /**
     * Set the key used to identify the form in the Redux store.
     * @param {string} key
     */
    setKey(key) {
        this._data.key = key;
    }

    /**
     * @returns {Object}
     */
    getErrors() {
        return this._data.errors ?? {};
    }

    /**
     * @param {Object} errors
     */
    setErrors(errors) {
        this._data.errors = errors;
        this.updateExternalState();
    }

    /**
     * @returns {boolean}
     */
    hasErrors() {
        return Object.values(this.getErrors()).includes(true);
    }

    /**
     * Calls the updateExternalState function, passing this instance as an argument.
     */
    updateExternalState() {
        this._updateExternalState(this);
    }

    /**
     * Validates the form and sets any errors.
     * @param {{ allowEmpty: boolean }} options
     */
    // eslint-disable-next-line no-unused-vars
    validate({ allowEmpty = false } = {}) {
        throw new Error('validate method must be implemented by subclass');
    }
}
