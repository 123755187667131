import L from "leaflet";

const MeasurementCircleEdge = L.Circle.extend({
    /**
     * Extending Circle class _containsPoint method so empty circles can be clicked through
     * @param {*} p
     * @returns {Boolean}
     */
    _containsPoint(p) {
        if (this.options.fill) {
            // If layer is filled then the whole circle is clickable
            return p.distanceTo(this._point) <= this._radius + this._clickTolerance();
        }

        // If layer is not filled then only the edge of the circle should be clickable
        return p.distanceTo(this._point) <= this._radius + this._clickTolerance() &&
            p.distanceTo(this._point) >= this._radius - this._clickTolerance();
    },
});

// Add a shortcut to the global Leaflet object
L.measurementCircleEdge = function shortcut(latLng, options) {
    return new MeasurementCircleEdge(latLng, options);
};

L.MeasurementCircleEdge = MeasurementCircleEdge;
