import ActivityBase from "./ActivityBase";

export default class ActivityGroup extends ActivityBase {
    static name = "ActivityGroup";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.group;
    }

    /**
     * @returns {Object}
     */
    get group() {
        return this.data.organisation;
    }

    /**
     * @returns {string}
     */
    get activityAction() {
        return this.data.activityAction;
    }

    /**
     * @returns {Object}
     */
    get updatedBy() {
        return this.data.updatedBy;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivityGroup-${this.ts}-${this.group.id}-${this.activityAction}`;
    }
}
