import React from "react";
import PropTypes from "prop-types";
import { EVENT_FILTER_NONE, EVENT_FILTERS, EVENT_SORT_BY } from "../../lib/const";
import { eventFilterAll, filterHas, getEventFilters } from "../../lib/events";

function SelectedMovementFilters(props) {
    if (
        props.eventsFilter === eventFilterAll(props.analysisTypeId)
        || props.eventsFilter === EVENT_FILTER_NONE
        || props.eventsSortBy === EVENT_SORT_BY.AIS_POINTS
    ) {
        return null;
    }

    return (
        <div className="u__pr--1">
            {
                Object.keys(getEventFilters(props.analysisTypeId)).map((key) => {
                    if (filterHas(props.eventsFilter, parseInt(key, 10))) {
                        return (
                            <div className="ui__panel__filter u__mt--05 u__mb--0 u__mr--025" key={key}>
                                <div className="ui__panel__filter-label">
                                    {EVENT_FILTERS[key]}
                                </div>
                                <button
                                    type="button"
                                    className="ui__panel__filter-remove"
                                    onClick={() => props.toggleEventsFilter(key)}
                                />
                            </div>
                        );
                    }
                    return null;
                })
            }
        </div>
    );
}

SelectedMovementFilters.propTypes = {
    eventsFilter: PropTypes.number.isRequired,
    analysisTypeId: PropTypes.string.isRequired,
    toggleEventsFilter: PropTypes.func.isRequired,
    eventsSortBy: PropTypes.string.isRequired,
};

export default SelectedMovementFilters;
