import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { initialise as initialiseAnalytics } from "./analytics";
import { isDevelopment } from "./env";
import { initMetrics } from "./promjs";
import { initIntercomEventTracking } from "./intercom";

export function initCookieConsent(user = null) {
    CookieConsent.run({
        cookie: {
            name: 'sb_cookie_consent',

            // Consent cookie should be available for starboard.nz and all subdomains
            domain: isDevelopment() ? window.location.hostname : "starboard.nz",
            expiresAfterDays: 365,
            sameSite: 'Lax',
        },
        categories: {
            necessary: {
                enabled: true,  // this category is enabled by default
                readOnly: true,  // this category cannot be disabled
            },
            analytics: {
                autoClear: {
                    cookies: [
                        { name: /^_ga/ },
                        { name: '_gid' },
                        { name: /^__hs/ },
                        { name: 'hubspotutk' },
                        { name: /^intercom-/ },
                    ],
                },
            },
        },
        onConsent: ({ cookie }) => {
            // Runs the first time user consents to cookies, and on every page load afterwards
            if (cookie.categories.includes('analytics')) {
                // enable google analytics and metrics tracking
                initialiseAnalytics(user?.id ?? null, user?.email ?? "");
                initIntercomEventTracking();

                if (user) {
                    initMetrics(user.metricsKey);
                }
            }
        },
        guiOptions: {
            consentModal: {
                layout: 'bar inline',
                position: 'bottom',
            },
        },
        language: {
            default: 'en',
            translations: {
                en: {
                    consentModal: {
                        title: 'We use cookies',
                        description: 'Some are necessary for Starboard to work, while others help us improve your experience. You can choose which cookies to accept by clicking on &ldquo;Manage individual preferences&rdquo;.',
                        acceptAllBtn: 'Accept',
                        acceptNecessaryBtn: 'Reject',
                        showPreferencesBtn: 'Manage individual preferences',
                    },
                    preferencesModal: {
                        title: 'Manage cookie preferences',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject',
                        savePreferencesBtn: 'Accept selection',
                        closeIconLabel: 'Close modal',
                        sections: [
                            {
                                linkedCategory: 'necessary',
                                title: 'Strictly necessary cookies',
                                description: 'These cookies are essential for the proper functioning of the website and cannot be disabled',
                            },
                            {
                                linkedCategory: 'analytics',
                                title: 'Analytics',
                                description: 'These cookies collect information about how you use our website',
                            },
                            {
                                title: 'More information',
                                description: 'More information about our cookies policy can be found in our <a href="https://starboard.nz/privacy">privacy statement</a>',
                            },
                        ],
                    },
                },
            },
        },
    });
}
