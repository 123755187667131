import { FORMS } from "../../../redux/slices/forms";
import { OPERATORS, SPEED_IN_AREA_MAX } from "../../const";
import { validPositiveNumber, validPositiveNumbers } from "../../measurement";
import { Form } from "./Form";
import SpeedOptionsAdapter from "../SpeedOptionsAdapter";

export default class VesselSpeedForm extends Form {
    /**
     * Create a new instance of a VesselSpeedForm based on data from a vessel activity filter
     * @param {Object} vesselFilter
     * @param {function(VesselSpeedForm)} updateExternalState
     * @param {string} distanceUnit
     * @returns {VesselSpeedForm}
     */
    static fromVesselFilter(vesselFilter, updateExternalState, distanceUnit) {
        return VesselSpeedForm.deserialize({
            ...SpeedOptionsAdapter.filterSetToForm(vesselFilter.speedOptions, distanceUnit),
            key: FORMS.MAP_FILTER_VESSEL_SPEED,
        }, updateExternalState);
    }

    /**
     * Create a new instance of a VesselSpeedForm based on data from an alert rule
     * @param {AlertRule} alertRule
     * @param {function(VesselSpeedForm)} updateExternalState
     * @param {string} distanceUnit
     * @returns {VesselSpeedForm}
     */
    static fromAlertRule(alertRule, updateExternalState, distanceUnit) {
        return VesselSpeedForm.deserialize({
            ...SpeedOptionsAdapter.filterSetToForm(alertRule.getSpeedInAreaFilterSpeedOptions(), distanceUnit),
            key: FORMS.ALERT_RULE_VESSEL_SPEED,
        }, updateExternalState);
    }

    /**
     * @returns {string}
     */
    getSpeedValue() {
        return this._data.value ?? "";
    }

    /**
     * @param {string} speedValue
     */
    setSpeedValue(speedValue) {
        this._data.value = speedValue;
        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {string}
     */
    getSpeedValue2() {
        return this._data.value2 ?? "";
    }

    /**
     * @param {string} speedValue2
     */
    setSpeedValue2(speedValue2) {
        this._data.value2 = speedValue2;
        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {string}
     */
    getSpeedOp() {
        return this._data.op ?? OPERATORS.GREATER_THAN;
    }

    /**
     * @param {string} speedOp
     */
    setSpeedOp(speedOp) {
        this._data.op = speedOp;
        this.validate({ allowEmpty: true });
        this.updateExternalState();
    }

    /**
     * @returns {boolean}
     */
    getIncludePortAreas() {
        return Boolean(this._data.includePortAreas);
    }

    /**
     * @param {boolean} includePortAreas
     */
    setIncludePortAreas(includePortAreas) {
        this._data.includePortAreas = includePortAreas;
        this.updateExternalState();
    }

    /**
     * Validates the form and sets any errors.
     * @param {{ allowEmpty: boolean }} options
     * @returns {boolean} true if valid, otherwise false
     */
    validate({ allowEmpty = false } = {}) {
        const newErrors = {};

        newErrors.speed1 = !validPositiveNumber(this.getSpeedValue(), { allowEmpty })
            || Number(this.getSpeedValue()) >= SPEED_IN_AREA_MAX;

        if (this.getSpeedOp() === OPERATORS.BETWEEN) {
            newErrors.speed2 = !validPositiveNumber(this.getSpeedValue2(), { allowEmpty })
                || Number(this.getSpeedValue2()) >= SPEED_IN_AREA_MAX;

            // If both values are valid, check that the first value is less than the second value.
            if (
                validPositiveNumbers([this.getSpeedValue(), this.getSpeedValue2()])
                && Number(this.getSpeedValue()) >= Number(this.getSpeedValue2())
            ) {
                newErrors.speed1 = true;
                newErrors.speed2 = true;
            }
        }

        this.setErrors(newErrors);
        return !this.hasErrors();
    }
}
