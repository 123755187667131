import { useSelector } from "react-redux";
import { ERRORS, ROLE_ADMIN, ROLE_SUPER_ADMIN } from "./const";
import { simpleCapitalize } from "./grammar";

/**
 * @param capabilityBitmap
 * @returns {boolean}
 */
export function isAdmin(capabilityBitmap) {
    return (capabilityBitmap & ROLE_ADMIN) === ROLE_ADMIN;
}

/**
 * @param roleBitmap
 * @returns {boolean}
 */
export function isSuperAdmin(roleBitmap) {
    return (roleBitmap & ROLE_SUPER_ADMIN) === ROLE_SUPER_ADMIN;
}

/**
 * Returns the users first organisation or null
 * @param user
 * @returns {null|*}
 */
export function getFirstOrganisation(user) {
    if (!user?.roles?.length) {
        return null;
    }
    return user.roles[0].organisation;
}

/**
 * @param user
 * @param orgId
 * @returns {boolean}
 */
export function userOrgIsValid(user, orgId) {
    if (!user?.roles?.length) {
        return false;
    }
    return user.roles.some((role) => role.organisation.id === orgId);
}

/**
 * @param {string} email
 * @returns {boolean}
 */
export function isEmployeeEmail(email) {
    return typeof email === "string" && (/@xerra\.nz$/.test(email) || /@starboard\.nz$/.test(email));
}

/**
 * Returns nuanced username depending if the username is the current user
 * @param {string} preferredName
 * @param {number} userId
 * @param {number} currentUserId
 * @param {object} options
 * @param {boolean} options.capitalize
 * @returns {string}
 */
export function getNormalisedUsername(preferredName, userId, currentUserId, { capitalize = false } = {}) {
    if (currentUserId === userId) {
        return `${preferredName} (${simpleCapitalize("me", capitalize)})`;
    }

    return preferredName;
}

/**
 * Returns a function that normalises a username by adding "me" if the user is the current user
 * @returns {(preferredName:string, userId:number, options:{capitalize:boolean}) => string}
 */
export function useNormalisedUsername() {
    const currentUserId = useSelector((state) => state.currentUser.id);
    return (preferredName, userId, options = {}) => getNormalisedUsername(preferredName, userId, currentUserId, options);
}

/**
 * Returns true if the error returned from deleteRole is regarding being the last manager of a group
 * @param {object} error
 * @returns {boolean}
 */
export function hasSoleManagerError(error) {
    const errors = error?.response?.errors;
    if (!Array.isArray(errors)) {
        return false;
    }

    return errors.some((err) => err.extensions?.code === ERRORS.ERR_NEW_ADMIN_REQUIRED);
}
